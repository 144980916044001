/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../../utils/token';

const SETTINGS_KEY = 'scanTotalMilesColumnSettings';

export const fetchTotalMilesColumnSettings = async () => {
	const url = `/settings/${SETTINGS_KEY}`;

	if (hasAccessToken()) {
		const fetchData = createPromiseRequest({
      url,
      method: 'GET',
      aip: 'account'
		});

		return fetchData();
	}
	return Promise.reject(new Error('Missing Token'));
};

export const postTotalMilesColumnSettings = async (columnKeys, columnKeysSortList) => {
	const url = '/updatesettings';

	if (hasAccessToken()) {
		const postData = createPromiseRequest({
      url,
      method: 'POST',
      aip: 'account',
      data: {
        [SETTINGS_KEY]: {
          columnKeys,
          columnKeysSortList
        }
      }
		});

		return postData();
	}
	return Promise.reject(new Error('Missing Token'));
};

