import React, { useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function createIdIfNotExists(id) {
	if (id) {
		return id;
	}

	return Math.random().toString();
}

// note: position and direction are the same.

export default function GeneralizedTooltip(props) {
	const { id: idFromProps, text, children, placement, direction = 'bottom', ...remainingProps } = props;

  const id = useMemo(() => createIdIfNotExists(idFromProps), [idFromProps]);
  const _placement = useMemo(() => (placement || direction), [placement, direction])

	return (
		<OverlayTrigger
      placement={_placement}
      { ...remainingProps}
			overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
		>
			{children}
		</OverlayTrigger>
	);
}
