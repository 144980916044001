import { thunkSet } from '../../LinesAggs/actions';
import { selectItems } from '../../LinesAggs/selectors';
import { SORT_DIRECTION } from '../constants';
import { selectSortColumn, selectSortDirection } from '../selectors';
import { setSorting } from './actions';

function getSortDirection(currentSortDirection, currentSortColumn, sortColumn) {
	if (currentSortColumn !== sortColumn) {
		return SORT_DIRECTION.ASC;
	}
	if (currentSortDirection === SORT_DIRECTION.ASC) {
		return SORT_DIRECTION.DESC;
	}
	return SORT_DIRECTION.ASC;
}

export const updateSorting = sortColumn => (dispatch, getState) => {
	const currentSortColumn = selectSortColumn(getState());
	const currentSortDirection = selectSortDirection(getState());
	const sortDirection = getSortDirection(
		currentSortDirection,
		currentSortColumn,
		sortColumn
	);
	dispatch(setSorting({ sortColumn, sortDirection }));
	const items = selectItems(getState());
	dispatch(thunkSet(items));
};
