import { YYYY_MM_DD } from 'aegion_common_utilities/lib/format/datetime';
import { getDateRangeTypeMeta } from './util/DateRangeTypes';

const YEAR_TO_DATE = getDateRangeTypeMeta('YEAR_TO_DATE');

const stringifyDate = date => {
	if (date instanceof Date) {
		return YYYY_MM_DD(date);
	}
	return date;
};

export const initialState = {
	type: YEAR_TO_DATE.key,
	from: stringifyDate(YEAR_TO_DATE.computeNewFrom()),
	to: stringifyDate(YEAR_TO_DATE.computeNewTo()),
	enabled: false
};

export default initialState;
