import { getCustomer } from "../../../../../util/user";
import {
  hasSettingAutoRemoveIliData,
  hasSettingEnableActionPlan,
  hasSettingEnableClosureReport, 
  hasSettingEnableLinesListCardsView, 
  hasSettingEnableLinesListTableView, 
  hasSettingEnableMyProjects, 
  hasSettingEnableReportListActionPlanAndClosureV1, 
  hasSettingEnableReportListActionPlanAndClosureV2, 
  hasSettingEnableReportPercentPassing, 
  hasSettingEnableReports, 
  hasSettingEnableReportTotalMiles,
  hasSettingFeatureClosureCopyActionPlan
} from "../../../../../util/customerAppSettings/scanline";

const EMPTY_OBJECT = {};

export const initialState = {
  customer: getCustomer(),
  analysisPageCustomer: undefined,
  customerSettingsSource: EMPTY_OBJECT, // Aegion User Only, to be pulled from cloud and referenced only when an Aegion User changes the customer dropdown.
  isLoadingCustomerSettingsSource: false,
  isLoadedCustomerSettingsSource: getCustomer() !== 'Aegion',

  settings: {
    enableActionPlan: hasSettingEnableActionPlan(),
    enableClosureReport: hasSettingEnableClosureReport(),
  
    enableLinesListCardsView: hasSettingEnableLinesListCardsView(),
    enableLinesListTableView: hasSettingEnableLinesListTableView(),
    enableMyProjects: hasSettingEnableMyProjects(),
  
    enableReports: hasSettingEnableReports(),
    enableReportListActionPlanAndClosureV1: hasSettingEnableReportListActionPlanAndClosureV1(),
    enableReportListActionPlanAndClosureV2: hasSettingEnableReportListActionPlanAndClosureV2(),
    enableReportPercentPassing: hasSettingEnableReportPercentPassing(),
    enableReportTotalMiles: hasSettingEnableReportTotalMiles(),
  
    autoRemoveIliData: hasSettingAutoRemoveIliData(),
    featureClosureCopyActionPlan: hasSettingFeatureClosureCopyActionPlan(),
  },
};

export default initialState;
