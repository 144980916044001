/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import {
	setRestrictionDefinitions,
	setIsLoadingLoadedRestrictionDefinitions,
	setHasLoadingErrorRestrictionDefinitions
} from './actions';
import { getRestrictionDefinitions } from '../../../../api/restrictionDefinitions';
import { listPsqlCustomersEntities } from '../../../../api/customersEntities';
import { getPermissionDefinitions } from '../../../../api/permissionDefinitions';
import {
	transformCloudDefinitionsToClientDefinitions,
	createMapRestrictionsByGroupName,
	createListRestrictionDefinitions,
	createMapRestrictionsListByAppKey
} from '../util.transform';
import { selectCustomer } from '../selectors';
import { listPsqlAreas } from '../../../../api/areas';

const loadRestrictionDefinitions = () => (dispatch, getState) => {
	const meta1 = {
		isLoadingRestrictionDefinitions: true,
		isLoadedRestrictionDefinitions: false
	};
	const customer = selectCustomer(getState());
	dispatch(
		setRestrictionDefinitions({
			restrictionDefinitions: {},
			mapRestrictionsByGroupName: {},
			listRestrictionDefinitions: [],
			mapRestrictionsListByAppKey: {}
		})
	);
	dispatch(setIsLoadingLoadedRestrictionDefinitions(meta1));

	Promise.all([listPsqlCustomersEntities(), listPsqlAreas()]).then(
		([
			{
				data: { [customer]: entitiesData = [] } = {},
				withJobsMap: { [customer]: withJobsMap } = {}
			} = {},
			{ data: { [customer]: areasData = [] } = {} }
		]) => {
			getPermissionDefinitions(undefined, (err, restrictionDefinitions) => {
				if (err) {
					dispatch(setHasLoadingErrorRestrictionDefinitions(true));
					dispatch(
						setIsLoadingLoadedRestrictionDefinitions({
							isLoadingRestrictionDefinitions: false,
							isLoadedRestrictionDefinitions: false
						})
					);
				} else {
					const formattedDefinitions = transformCloudDefinitionsToClientDefinitions(
						restrictionDefinitions,
						entitiesData,
						areasData
					);
					const mapRestrictionsByGroupName = createMapRestrictionsByGroupName(
						formattedDefinitions
					);
					const listRestrictionDefinitions = createListRestrictionDefinitions(
						formattedDefinitions
					);
					const mapRestrictionsListByAppKey = createMapRestrictionsListByAppKey(
						formattedDefinitions
					);

					dispatch(
						setRestrictionDefinitions({
							restrictionDefinitions: formattedDefinitions,
							mapRestrictionsByGroupName,
							listRestrictionDefinitions,
							mapRestrictionsListByAppKey
						})
					);
					dispatch(
						setIsLoadingLoadedRestrictionDefinitions({
							isLoadingRestrictionDefinitions: false,
							isLoadedRestrictionDefinitions: true
						})
					);
				}
			});
		}
	);
};

export { loadRestrictionDefinitions };
