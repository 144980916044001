/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { set, clear } from './actions';
import { ROOT_SLICE } from './constants';
import { initialState } from './initialState';
import {
  selectThisSlice,
  selectTabManagers,
  selectTabManager
} from './selectors';

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
			[ROOT_SLICE]: _.cloneDeep(initialState)
		};
	},

  [set]: (state, { payload: { 
    namespace,
    tabId,
    thisTab,
    tabs,
    otherTabs,
    tabsMap  
  } }) => {
    if (!namespace) {
      return state;
    }
    const rootSlice = selectThisSlice(state);
    const tabManagers = selectTabManagers(state);
    const tabManager = selectTabManager(state, namespace) || {};
		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        tabManagers: {
          ...tabManagers,
          [namespace]: {
            ...tabManager,
            tabId,
            thisTab,
            tabs,
            otherTabs,
            tabsMap
          }
        }
			}
		};
  }
};

export default reducerParts;
