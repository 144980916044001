/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

/** ************************
 * CORE
 */

const {
  set,
  setCustomer,
  setIsLoadingLoaded,
  setErrorMessage,
  clear
} = createActions(
	{
    SET: (filters) => ({ filters }),
    SET_CUSTOMER: (customerName) => ({ customer: customerName }),
		SET_IS_LOADING_LOADED: ({ isLoading, isLoaded }) => ({
			isLoading,
			isLoaded
		}),
		SET_ERROR_MESSAGE: errorMessage => ({ errorMessage }),
		CLEAR: () => ({})
	},
	{ prefix: ROOT_SLICE }
);



/** ************************
 * DYNAMIC
 */

const {
  setLinesAggs,
  selectFilterItem,
  unselectFilterItem,
  clearSelectedFilters
} = createActions(
	{
    SET_LINES_AGGS: (linesAggs) => ({ linesAggs }),
    SELECT_FILTER_ITEM: (filterKey, filterItem) => ({filterKey, filterItem}),
    UNSELECT_FILTER_ITEM: (filterKey, filterItem) => ({ filterKey, filterItem }),
    CLEAR_SELECTED_FILTERS: () => { },
	},
	{ prefix: ROOT_SLICE }
);


export {
  // CORE
  set,
  setCustomer,
  setIsLoadingLoaded,
  setErrorMessage,
  clear,

  // DYNAMIC
  setLinesAggs,
  selectFilterItem,
  unselectFilterItem,
  clearSelectedFilters
};
