/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// SCANLINE SETTINGS
import { ROOT_SLICE as ROOT_SLICE_SCANLINE_SETTINGS } from './slices/ScanlineSettings/constants';
import { initialState as ScanlineSettings } from './slices/ScanlineSettings/initialState';
import ScanlineSettingsReducerParts from './slices/ScanlineSettings/reducerParts';

const initialState = {
  [ROOT_SLICE_SCANLINE_SETTINGS]: {
    ...ScanlineSettings
  }
};

const reducerParts = {
  ...ScanlineSettingsReducerParts,
};

export { ROOT_SLICE } from './constants'
export default handleActions(reducerParts, initialState);
