import { getCustomer } from "../../../../../util/user";
import {
  INVERTED_FILTER_KEYS_MAP,
  AEGION_ONLY_FILTER_KEYS,
  REQUIRED_FILTER_KEYS,
  DEFAULT_FILTER_KEYS,
  DEFAULT_FILTER_KEYS_SORT_LIST
} from "./constants";

const sessionCustomer = getCustomer();


/**
 * HELPERS
 */

const isDefined = v => v !== undefined && v !== null;

const isRequiredFilter = (key) => !!REQUIRED_FILTER_KEYS[key];

const canUseFilter = (key) => (sessionCustomer === 'Aegion' || !AEGION_ONLY_FILTER_KEYS[key]) && !!INVERTED_FILTER_KEYS_MAP[key];

const hasFilter = (filterMap, key) => !!filterMap[key];

/**
 * TOGGLE FILTER
 */

const enableFilter = (filterMap, key) => {
  if (!canUseFilter(key) || hasFilter(filterMap, key)) {
    return filterMap;
  }

  return {
    ...filterMap,
    [key]: key
  }
};

const disableFilter = (filterMap, key) => {
  if (!isRequiredFilter(key) && hasFilter(filterMap, key)) { 
    const clone = { ...filterMap };
    delete clone[key];
    return clone;
  }

  return filterMap;
}

const toggleFilter = (filterMap, key) => {
  if (hasFilter(filterMap, key)) {
    return disableFilter(filterMap, key);
  }

  return enableFilter(filterMap, key);
}

/**
 * CHANGE SORT POSITION
 */

const protectedIdx = (filterSortList, key, toIdx) => { 
  if (!isDefined(toIdx)) {
    return Math.max(0, filterSortList.length - 1);
  }
  if (toIdx < 0) { 
    return 0;
  }
  if (toIdx > filterSortList.length - 1) {
    return Math.max(0, filterSortList.length - 1);
  }
  return toIdx;
}

const moveFilterSort = (filterSortList, key, toIdx) => {
  const _toIdx = protectedIdx(filterSortList, key, toIdx);
  let newList = filterSortList.filter(k => k !== key);
  newList = [...newList.slice(0, _toIdx), key, ...newList.slice(_toIdx)];
  return newList;
}

/**
 * MERGE SERVER STATE WITH CLIENT STATE
 */

const replaceDefaultFilterKeys = (filterKeys = {}) => {
  const newFilterKeys = { ...filterKeys };

  if (Object.keys(newFilterKeys).length === 0) {
    return { ...DEFAULT_FILTER_KEYS }
  }

  if (sessionCustomer !== 'Aegion') {
    // remove AEGION_ONLY_FILTER_KEYS
    Object.keys(AEGION_ONLY_FILTER_KEYS).forEach(key => {
      delete newFilterKeys[key];
    });
  }

  // Add Missing REQUIRED_FILTER_KEYS
  Object.keys(REQUIRED_FILTER_KEYS).forEach(key => {
    if (!newFilterKeys[key]) {
      newFilterKeys[key] = key;
    }
  });

  return newFilterKeys;
};

const replaceDefaultFilterKeySortList = (filterKeysSortList) => {
  if (!filterKeysSortList) {
    return [...DEFAULT_FILTER_KEYS_SORT_LIST];    
  }
  const missingKeys = {...INVERTED_FILTER_KEYS_MAP};
  const newList = filterKeysSortList.filter(key => !!INVERTED_FILTER_KEYS_MAP[key]);
  newList.forEach(key => { 
    delete missingKeys[key]
  });

  DEFAULT_FILTER_KEYS_SORT_LIST.forEach(key => { 
    if (missingKeys[key]) {
      newList.push(key);
    }
  })
  return newList
}

/**
 * EXPORTS
 */

export { 
  isRequiredFilter,

  enableFilter,
  disableFilter,
  toggleFilter,

  moveFilterSort,

  replaceDefaultFilterKeys,
  replaceDefaultFilterKeySortList
}

export default {
  isRequiredFilter, 

  enableFilter,
  disableFilter,
  toggleFilter,

  moveFilterSort,

  replaceDefaultFilterKeys,
  replaceDefaultFilterKeySortList
}