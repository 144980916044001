import React, { useMemo } from 'react';
import { computeTooltipTitleObject } from './util.and.constants';

import TitleTooltip from '../TitleTooltip';

import './AimButtons.scss';
import './AimButtons.material.scss';

const AimLink = ({
	children,
  title,
  disabled,
	iconClassname, // remove from remainingProps
	bsStyle = 'primary',
	underlineOnHover = false,

	...remainingProps
}) => {
  const _title = useMemo(() => computeTooltipTitleObject(title, disabled), [title, disabled])
	const className = useMemo(
		() => `a-${bsStyle} ${underlineOnHover ? 'underline-on-hover' : ''}`,
		[bsStyle, underlineOnHover]
	);
  return (
    <TitleTooltip title={_title}>
      <a className={className} disabled={disabled} {...remainingProps}>
        {children}
      </a>
    </TitleTooltip>
	);
};

export default AimLink;
