import { loadUsers, reset } from '../../redux/slices/Users/actions';
import { loadCompanies } from '../../redux/slices/Companies/actions';
import {
	loadPermissionDefinitions,
	setCustomer as permissionDefinitionsSetCustomer
} from '../../redux/slices/PermissionDefinitions/actions';
import { selectCompanyName } from '../../redux/slices/UsersSearch/selectors';
import {
	loadRestrictionDefinitions,
	setCustomer as restrictionDefinitionsSetCustomer
} from '../../redux/slices/RestrictionDefinitions/actions';

export const loadAppData = () => dispatch => {
	dispatch(loadUsers());
	dispatch(loadCompanies());
	dispatch(loadPermissionDefinitions());
	dispatch(loadRestrictionDefinitions());
};

export const reloadUsers = () => (dispatch, getState) => {
	dispatch(reset());
	dispatch(loadUsers());

	const customer = selectCompanyName(getState());
	dispatch(permissionDefinitionsSetCustomer(customer));
	dispatch(restrictionDefinitionsSetCustomer(customer));
	dispatch(loadRestrictionDefinitions());
};

export default { loadAppData, reloadUsers };
