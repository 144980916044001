/* eslint-disable import/no-named-as-default-member */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { createSelector } from 'reselect';
import { ROOT_SLICE } from './constants';
import PSQL_USERS from '../Sync/PsqlUsers/selectors';
import { selectRootSliceAdmin } from '../../Main/selectors';
// import PSQL_USERS from '../Sync/PsqlUsers/selectors';


export const selectThisSlice = state => { 
  const slice = selectRootSliceAdmin(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already lines aggs.
  return state;
};


export const selectUsers = state => {
	const slice = selectThisSlice(state);
	const { users } = slice;
	return users;
};

export const selectIsLoadingUsers = state => {
	const slice = selectThisSlice(state);
	const { isLoadingUsers } = slice;
	return isLoadingUsers;
};

export const selectIsLoadedUsers = state => {
	const slice = selectThisSlice(state);
	const { isLoadedUsers } = slice;
	return isLoadedUsers;
};

export const selectHasLoadingErrorUsers = state => {
	const slice = selectThisSlice(state);
	const { hasLoadingErrorUsers } = slice;
	return hasLoadingErrorUsers;
};

export const selectUsersTotalCount = state => {
	const slice = selectThisSlice(state);
	const { usersTotalCount } = slice;
	return usersTotalCount;
};

export const selectIsLoadedUsersCount = createSelector(
	selectUsers,
	users => (users || []).length
);

export const selectSelectedUser = state => {
	const slice = selectThisSlice(state);
	const { selectedUser } = slice;
	return selectedUser;
};

export const selectSelectedUserChangesClone = state => {
	const slice = selectThisSlice(state);
	const { selectedUserChangesClone } = slice;
	return selectedUserChangesClone;
};

export const selectIsSavingUser = state => {
	const slice = selectThisSlice(state);
	const { isSavingUser } = slice;
	return isSavingUser;
};

export const selectHasChanges = createSelector(
	selectSelectedUser,
	selectSelectedUserChangesClone,
	(selectedUser, selectedClone) => {
		const user = _.cloneDeep(selectedUser) || {};
		const user_hasGroupHashMap = Object.keys(user.hasGroupHashMap || {}).reduce(
			(acc, k) => {
				if (user.hasGroupHashMap[k]) {
					acc[k] = true;
				}
				return acc;
			},
			{}
		);
		delete user.hasGroupHashMap;

		const clone = _.cloneDeep(selectedClone) || {};
		const clone_hasGroupHashMap = Object.keys(
			clone.hasGroupHashMap || {}
		).reduce((acc, k) => {
			if (clone.hasGroupHashMap[k]) {
				acc[k] = true;
			}
			return acc;
		}, {});
		delete clone.hasGroupHashMap;

		return (
			user &&
			clone &&
			(!_.isEqual(user, clone) ||
				!_.isEqual(user_hasGroupHashMap, clone_hasGroupHashMap))
		);
	}
);

export const selectNewUser = state => {
	const slice = selectThisSlice(state);
	const { newUser } = slice;
	return newUser;
};

export const selectIsSavingNewUser = state => {
	const slice = selectThisSlice(state);
	const { isSavingNewUser } = slice;
	return isSavingNewUser;
};

export const selectErrorMessageSaveNewUser = state => {
	const slice = selectThisSlice(state);
	const { errorMessageSaveNewUser } = slice;
	return errorMessageSaveNewUser;
};

export const selectIsSavingEditedUser = state => {
	const slice = selectThisSlice(state);
	const { isSavingEditedUser } = slice;
	return isSavingEditedUser;
};

export const selectErrorMessageSaveEditedUser = state => {
	const slice = selectThisSlice(state);
	const { errorMessageSaveEditedUser } = slice;
	return errorMessageSaveEditedUser;
};

export const selectIsDeletingUser = state => {
	const slice = selectThisSlice(state);
	const { isDeletingUser } = slice;
	return isDeletingUser;
};

export const selectErrorMessageDeleteUser = state => {
	const slice = selectThisSlice(state);
	const { errorMessageDeleteUser } = slice;
	return errorMessageDeleteUser;
};

export const selectShowConfirmation = state => {
	const slice = selectThisSlice(state);
	const { showConfirmation } = slice;
	return showConfirmation;
};

export const selectConfirmationMessageKey = state => {
	const slice = selectThisSlice(state);
	const { confirmationMessageKey } = slice;
	return confirmationMessageKey;
};

export const selectUserIsInPsql = (state, { username = '' } = {}) => {
	const boolMap = PSQL_USERS.selectBoolMap(state);
	return boolMap[username];
};

export const selectUserHasPermissionsMismatch = (state, { username = '' } = {}) => {
  const users = selectUsers(state);
  const user = users.find(u => u.username === username);
  const allUsersGroupnames = PSQL_USERS.selectPsqlGroupnames(state) || {};
  const { hasGroupHashMap } = user || {};

  const cognitoGroupnamesMap = hasGroupHashMap || {};
  const cognitoGroupnames = Object.keys(cognitoGroupnamesMap);
  const { awsGroupnames = [], awsGroupnamesMap: psqlGroupnamesMap = {} } = allUsersGroupnames[username] || {};

  // short circuit if user is missing aws groupname for their company
  if (awsGroupnames.filter(v => v).length === 0) {
    return true;
  }

  const psqlGroupnames = awsGroupnames.filter(gn => gn && gn.indexOf('Customer:') !== 0);
  const keys = [...new Set([...cognitoGroupnames, ...psqlGroupnames])];
  const hasMismatch = keys.some(k => (!cognitoGroupnamesMap[k] && psqlGroupnamesMap[k]) || (cognitoGroupnamesMap[k] && !psqlGroupnamesMap[k]));

  return hasMismatch;
};

export const selectIsOutOfSync = (state, { username = '' } = {}) => {
	return !selectUserIsInPsql(state, { username }) || selectUserHasPermissionsMismatch(state, { username });
};
