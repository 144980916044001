
import initialState from './initialState';
import { ROOT_SLICE } from './constants';
import {
  setType,
  setFromAndTo,
  setEnabled,
  clear
} from './actions';
import { selectDateRangeFrom, selectDateRangeTo, selectThisSlice } from './selectors';

// ************
// PARTS
// ************
const reducerParts = {
  [clear]: state => {
    const rootSlice = selectThisSlice(state);

    return {
      ...state,
      [ROOT_SLICE]: {
        ...rootSlice,
        ...initialState
      }
    };
  },

	[setType]: (
		state,
		{ payload: { type } }
	) => {
		const rootSlice = selectThisSlice(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				type
			}
		};
  },

	[setEnabled]: (
		state,
		{ payload: { enabled } }
	) => {
		const rootSlice = selectThisSlice(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				enabled
			}
		};
  },

	[setFromAndTo]: (
		state,
		{ payload: { from, to } }
	) => {
		const rootSlice = selectThisSlice(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				from,
				to
			}
		};
  },  

};


export default reducerParts;
