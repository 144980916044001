/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { DEFAULT_COLUMN_KEYS, DEFAULT_COLUMN_KEYS_SORT_LIST } from '../constants';
import { fetchLinkSurveysColumnSettings, postLinkSurveysColumnSettings } from '../../../api/linksurveyscolumnsettings';
import { selectColumnKeys, selectColumnKeysSortList, selectIsLoaded, selectIsLoading } from '../selectors';
import {
  setSettings,
  setIsLoadingLoaded,
  clear,
  resetToDefaults,
  enableColumn,
  disableColumn,
  toggleColumn,
  moveColumnSort
} from './actions';

const isEmpty = obj => {
  if (obj instanceof Array) {
    return obj.length === 0;
  }

  if (typeof obj === 'object') { 
    return Object.keys(obj).length === 0;
  }

  return !obj;
}

const thunkLoad = () => (dispatch) => {
  dispatch(clear());
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
  fetchLinkSurveysColumnSettings().then((res) => {
    if (!isEmpty(res)) { 
      const { columnKeys, columnKeysSortList } = res;
      dispatch(
        setSettings(
          columnKeys || {...DEFAULT_COLUMN_KEYS},
          columnKeysSortList || [...DEFAULT_COLUMN_KEYS_SORT_LIST]      
        )
      );
    }
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

const thunkLoadIfNotLoaded = () => (dispatch, getState) => {
  const isLoaded = selectIsLoaded(getState());
  const isLoading = selectIsLoading(getState());

  if (!isLoaded && !isLoading) {
    dispatch(thunkLoad());
  }
};

const thunkSave = () => (_dispatch, getState) => {
  const columnKeys = selectColumnKeys(getState());
  const columnKeysSortList = selectColumnKeysSortList(getState());
  postLinkSurveysColumnSettings(columnKeys, columnKeysSortList);
};

const thunkEnableColumn = (key) => (dispatch) => {
  dispatch(enableColumn(key));
  dispatch(thunkSave());
};

const thunkDisableColumn = (key) => (dispatch) => { 
  dispatch(disableColumn(key));
  dispatch(thunkSave());
};

const thunkToggleColumn = (key) => (dispatch) => { 
  dispatch(toggleColumn(key));
  dispatch(thunkSave());
};

const thunkMoveColumnSort = (key, toIdx) => (dispatch) => { 
  dispatch(moveColumnSort(key, toIdx));
  dispatch(thunkSave());
};

const thunkResetToDefaults = () => (dispatch) => { 
  dispatch(resetToDefaults());
  dispatch(thunkSave());
}



export {
  thunkLoad,
  thunkLoadIfNotLoaded,
  thunkSave,

  thunkResetToDefaults,

  thunkEnableColumn,
  thunkDisableColumn,
  thunkToggleColumn,
  thunkMoveColumnSort
};
