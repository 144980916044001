import { getCustomer } from "../../../../util/user";
import {
  getFilters,
  KEY_COMPANY
} from "../../../util/filters";

const startingFilters = getFilters();
const sessionCustomer = getCustomer();
const canChangeCustomer = sessionCustomer === 'Aegion';



export const initialState = {
  customer: canChangeCustomer
  ? startingFilters[KEY_COMPANY] || sessionCustomer
  : sessionCustomer,
	permissionDefinitions: {},
	mapPermissionsByGroupName: {},
	listPermissionDefinitions: [],
	mapPermissionsListByAppKey: {},

	isLoadingPermissionDefinitions: false,
	isLoadedPermissionDefinitions: false,
	hasLoadingErrorPermissionDefinitions: false
};

export default initialState;
