/* eslint import/no-cycle:0 */
import ReduxThunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import appScanlineReducer from '../../reducers/newAppScanline';
// @todo @wbfix - the changes to original appScanlineReducer need to be manually added to new appScanlineReducer
// import appScanlineReducer from '../../reducers/appScanline';
import scanReportsList from '../../redux/ReportsList';
import jobsReducer from '../../components/JobsList/reducers/jobsReducer';
import fieldlineJobsReducer from '../../../fieldline/components/JobsList/reducers/jobsReducer';
import cisview from '../../../cisview/redux/reducers';
import projects from '../../redux/Projects/reducer';
import lineFilters from '../../redux/LineFilters/reducer';
import projectFilters from '../../redux/ProjectFilters/reducer';
import lines from '../../redux/Lines/reducer';
import line from '../../redux/Line/reducer';
import cardColor from '../../redux/CardColor/reducer';
import AssetViewReducers from '../../../assetView/reducers/reducers';
import lineRoutings from '../../redux/Line/routing';
import assetViewRoutings from '../../../assetView/redux/routing/map';
import assetViewCharts from '../../../assetView/redux/Charts/reducer';
import assetDetails from '../../../assetView/redux/AssetDetails/reducer';
import UploaderReducer from '../../../commons/components/surveyloader/redux/reducer';
import fieldlineMap from '../../../fieldline/redux/Maps/reducer';
import chargelineFilters from '../../../chargeline/Redux/ProjectFilter/reducer';
import blurModalReducer from '../../redux/BlurModal/reducer';
import videoline from '../../../videoline/redux/reducers';
import accountReducer from '../../../account/redux/reducers';
import mainReducer from '../../../main/redux/reducer';
import websiteBridge from '../../redux/WebsiteBridge';
import websiteExperimental from '../../redux/WebsiteExperimental';
import avReducer from '../../../assetView/redux/reducers';
import liveline from '../../../liveline/redux/reducers';
import commonReducer from '../../../commons/redux/reducers';
import timeOnPageMeta from '../../redux/TimeOnPageMeta';
import dashboardCisvReducer from '../../../dashboardsAndReports/dashboards/DashboardCISV/redux';
import { ROOT_SLICE_DASH_CISV } from '../../../dashboardsAndReports/dashboards/DashboardCISV/redux/constants';
import DITSurveysNoReadingsReducer from '../../../dataintegrity/components/NoReadingsTest/redux';
import { ROOT_SLICE_SURVEYS_NO_READINGS } from '../../../dataintegrity/components/NoReadingsTest/redux/constants';
import DITActionPlanReportsReducer from '../../../dataintegrity/components/ActionPlanClosureReport/redux';
import { ROOT_SLICE_AP_CLOSURE_REPORTS } from '../../../dataintegrity/components/ActionPlanClosureReport/redux/constants';
import dataDeliveryReportReducer from '../../../dataintegrity/components/DataDeliveryReport/redux';
import { ROOT_SLICE as ROOT_SLICE_DATA_DELIVERY_REPORT } from '../../../dataintegrity/components/DataDeliveryReport/redux/constants';
import fileDiffReducer, {
	FILE_DIFF_REDUX_ROOT_KEY
} from '../../../dataintegrity/components/FileDiff/redux';
import { ROOT_SLICE_ADMIN } from '../../../admin/redux/constants';
import AdminReducer from '../../../admin/redux';
import LinesProjectsReportsReducer, {
	ROOT_SLICE as ROOT_SLICE_LPR
} from '../../redux/LinesProjectsReports';
import MainMapPopoutState, {
	ROOT_SLICE as ROOT_SLICE_MMPS
} from '../../redux/MainMapPopoutState';
import CustomerAppSettingdReducer, {
	ROOT_SLICE as ROOT_SLICE_CAS
} from '../../redux/CustomerAppSettings';

import { isLocalHost } from '../../utils/location';

export const sagaMiddleware = createSagaMiddleware();

export const browserHistory = createBrowserHistory();

const assetViewMain = new AssetViewReducers();
const routings = [...lineRoutings, ...assetViewRoutings];

export const middleware = [sagaMiddleware, ReduxThunk, ...routings];

//export const enhancers = [];

export const enhancers = isLocalHost()
	? [
			window.__REDUX_DEVTOOLS_EXTENSION__ &&
				window.__REDUX_DEVTOOLS_EXTENSION__()
	  ]
	: [];

export const reducers = {
	account: accountReducer,
	app: appScanlineReducer,
	common: commonReducer,
	scanReportsList,
	jobs: jobsReducer,
	cisview,
	fieldlineJobs: fieldlineJobsReducer,
	lineFilters,
	projectFilters,
	lines,
	projects,
	line,
	cardColor,
	assetViewMain: assetViewMain.reducer,
	assetViewCharts,
	assetview: avReducer,
	assetDetails,
	fieldlineMap,
	chargelineFilters,
	blurModal: blurModalReducer,
	videoline,
	main: mainReducer,
	websiteBridge,
	websiteExperimental,
	timeOnPageMeta,
	uploader: UploaderReducer,
	liveline,
	[ROOT_SLICE_DASH_CISV]: dashboardCisvReducer,
	[ROOT_SLICE_SURVEYS_NO_READINGS]: DITSurveysNoReadingsReducer,
	[ROOT_SLICE_AP_CLOSURE_REPORTS]: DITActionPlanReportsReducer,
	[ROOT_SLICE_DATA_DELIVERY_REPORT]: dataDeliveryReportReducer,
	[FILE_DIFF_REDUX_ROOT_KEY]: fileDiffReducer,
	[ROOT_SLICE_ADMIN]: AdminReducer,
	[ROOT_SLICE_LPR]: LinesProjectsReportsReducer,
	[ROOT_SLICE_MMPS]: MainMapPopoutState,
	[ROOT_SLICE_CAS]: CustomerAppSettingdReducer
};
