/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
import _ from 'lodash';
import {
  set,
  setCustomer,
  setIsLoadingLoaded,
  setErrorMessage,
  clear,

  selectFilterItem,
  unselectFilterItem,
  clearSelectedFilters
} from './actions';
import { ROOT_SLICE } from './constants';
import { initialState } from './initialState';
import {
	selectThisSlice,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage,
  selectSelectedFiltersArr,
  selectSelectedFiltersArrByFilterKey,
  selectSelectedFiltersMap
} from './selectors';
import UTIL from './util.selectedFilters';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
			[ROOT_SLICE]: _.cloneDeep(initialState)
		};
	},

  [set]: (state, { payload: { filters = {} } = {} } = {}) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        filters
			}
		};
  },
  
  [setCustomer]: (state, { payload: { customer } }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        customer
			}
		};
  },

	[setIsLoadingLoaded]: (
		state,
		{ payload: { isLoading: newIsLoading, isLoaded: newIsLoaded } }
	) => {
		const rootSlice = selectThisSlice(state);
		const isLoading = selectIsLoading(state);
		const isLoaded = selectIsLoaded(state);

		const CHANGED = {
			isLoading: isDefined(newIsLoading) && newIsLoading !== isLoading,
			isLoaded: isDefined(newIsLoaded) && newIsLoaded !== isLoaded
		};

		if (!CHANGED.isLoaded && !CHANGED.isLoading) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoading: CHANGED.isLoading ? newIsLoading : isLoading,
				isLoaded: CHANGED.isLoaded ? newIsLoaded : isLoaded
			}
		};
	},

	[setErrorMessage]: (
		state,
		{ payload: { errorMessage: newErrorMessage } }
	) => {
		const rootSlice = selectThisSlice(state);
		const errorMessage = selectErrorMessage(state);

		if (newErrorMessage === errorMessage) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				errorMessage: newErrorMessage
			}
		};
  },

  [selectFilterItem]: (
    state,
    { payload: {filterKey, filterItem} }
  ) => {
		const rootSlice = selectThisSlice(state);
    const currArr = selectSelectedFiltersArr(state);
    const currArrByKey = selectSelectedFiltersArrByFilterKey(state, filterKey);
    const currMap = selectSelectedFiltersMap(state);

    const {
      selectedFiltersArr,
      selectedFiltersArrByFilterKey,
      selectedFiltersMap
    } = UTIL.addSelectedFilter(
      filterKey,
      filterItem,
      {
        selectedFiltersArr: currArr,
        selectedFiltersArrByFilterKey: currArrByKey,
        selectedFiltersMap: currMap
      }
    );

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        selectedFiltersArr,
        selectedFiltersArrByFilterKey,
        selectedFiltersMap
      }
		};
  },
  
  [unselectFilterItem]: (
    state,
    { payload: {filterKey, filterItem} }
  ) => {
		const rootSlice = selectThisSlice(state);
    const currArr = selectSelectedFiltersArr(state);
    const currArrByKey = selectSelectedFiltersArrByFilterKey(state, filterKey);
    const currMap = selectSelectedFiltersMap(state);

    const {
      selectedFiltersArr,
      selectedFiltersArrByFilterKey,
      selectedFiltersMap
    } = UTIL.removeSelectedFilter(
      filterKey,
      filterItem,
      {
        selectedFiltersArr: currArr,
        selectedFiltersArrByFilterKey: currArrByKey,
        selectedFiltersMap: currMap
      }      
    );

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        selectedFiltersArr,
        selectedFiltersArrByFilterKey,
        selectedFiltersMap
      }
		};
  },

  [clearSelectedFilters]: (state) => { 
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        selectedFiltersArr: [],
        selectedFiltersArrByFilterKey: {},
        selectedFiltersMap: {}
      }
		};
  }

};

export default reducerParts;
