import { selectRootMainMapPopoutState } from '../../Main/selectors';
import { ROOT_SLICE } from './constants';

export const selectThisSlice = state => { 
  const slice = selectRootMainMapPopoutState(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already DebugTabManagers.
  return state;
};

export const selectTabManagers = state => {
	const slice = selectThisSlice(state);
  const { tabManagers } = slice;
	return tabManagers;
};

export const selectTabManager = (state, namespace) => {
  const tabManagers = selectTabManagers(state);
  return (tabManagers || {})[namespace];
};

export default {
	selectTabManagers,
	selectTabManager
};
