/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
  setSettings,
  setIsLoadingLoaded,
  setErrorMessage,
  clear,
  resetToDefaults,

  enableFilter,
  disableFilter,
  toggleFilter,

  moveFilterSort
} = createActions(
	{
    SET_SETTINGS: (filterKeys, filterKeysSortList) => ({ filterKeys, filterKeysSortList }),
		SET_IS_LOADING_LOADED: ({ isLoading, isLoaded }) => ({
			isLoading,
			isLoaded
		}),
		SET_ERROR_MESSAGE: errorMessage => ({ errorMessage }),
		CLEAR: () => ({}),
    
    RESET_TO_DEFAULTS: () => ({}),

    ENABLE_FILTER: (key) => ({ key }),
    DISABLE_FILTER: (key) => ({ key }),
    TOGGLE_FILTER: (key) => ({ key }),

    MOVE_FILTER_SORT: (key, toIdx) => ({key, toIdx})
	},
	{ prefix: ROOT_SLICE }
);

export {
  setSettings,
  setIsLoadingLoaded,
  setErrorMessage,
  clear,
  resetToDefaults,

  enableFilter,
  disableFilter,
  toggleFilter,

  moveFilterSort
};
