import { DEFAULT_COLUMN_KEYS, DEFAULT_COLUMN_KEYS_SORT_LIST } from "./constants";

export const initialState = {
  columnKeys: {...DEFAULT_COLUMN_KEYS},
  columnKeysSortList: [...DEFAULT_COLUMN_KEYS_SORT_LIST],
	isLoading: false,
	isLoaded: false,
  errorMessage: undefined,
};

export const stateChangesForReset = {
	isLoading: initialState.isLoading,
	isLoaded: initialState.isLoaded,
  errorMessage: initialState.errorMessage
}

export default initialState;
