import { SET_APPLICATIONS, SET_APPLICATIONS_IS_LOADING_IS_LOADED } from './actions';

const defaultState = {
	applications: []
};

const reducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {

    case SET_APPLICATIONS:
			return { ...state, applications: payload, isLoaded: true, isLoading: false };

    case SET_APPLICATIONS_IS_LOADING_IS_LOADED:
      return { ...state, isLoading: payload.isLoading, isLoaded: payload.isLoaded };

    default:
      return state;
	}
};

export default reducer;
