/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

/** ************************
 * CORE
 */

const {
  setIsEditorOpen,
  setSelectedItem,
  setData,
  setIsDataLoadingLoaded,
  setErrorMessage,
  clear
} = createActions(
	{
    SET_IS_EDITOR_OPEN: (open) => ({ open }),
    SET_SELECTED_ITEM: (item) => ({ item }),
    SET_DATA: (data) => ({ data }),
		SET_IS_DATA_LOADING_LOADED: ({ isDataLoading, isDataLoaded }) => ({
      isDataLoading,
      isDataLoaded
		}),
		SET_ERROR_MESSAGE: errorMessage => ({ errorMessage }),
		CLEAR: () => ({})
	},
	{ prefix: ROOT_SLICE }
);


export {
  // CORE
  setIsEditorOpen,
  setSelectedItem,
  setData,
  setIsDataLoadingLoaded,
  setErrorMessage,
  clear
};
