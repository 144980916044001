/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { store } from '../../../../../../scanline/store';
import { set, setPsqlGroupnames, setIsLoadingLoaded, clear } from './actions';
import { listPsqlUsersV2, psqlGetGroupnames } from '../../../../../api/users';
import { selectRootSliceAdmin } from '../../../../Main/selectors';
import { selectCompanyName } from '../../../UsersSearch/selectors';

const thunkLoad = (userids) => (dispatch) => {
	const stateAdmin = selectRootSliceAdmin(store.getState());
  const customer = selectCompanyName(stateAdmin);
	dispatch(clear());
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
  );
  Promise.all([psqlGetGroupnames(customer, userids), listPsqlUsersV2(userids)]).then(([psqlGroupnames, userData]) => {
    dispatch(setPsqlGroupnames(psqlGroupnames));
		dispatch(set(userData));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
  });
};

export { thunkLoad };
