/* eslint-disable import/no-cycle */
import _ from 'lodash';
import {
  setCustomer,
  setAnalysisPageCustomer,
  setIsLoadingLoaded, 
  setCustomerSettingsSource, 
  replaceSettingsForCustomer,
  clear,
} from './actions';
import { ROOT_SLICE } from './constants';
import { initialState } from './initialState';
import {
	selectThisSlice,
	selectIsLoading,
  selectIsLoaded,
  selectCustomer,
  selectCustomerSettingsSource,
} from './selectors';
import { hasSettingAutoRemoveIliData, hasSettingEnableActionPlan, hasSettingEnableClosureReport, hasSettingEnableLinesListCardsView, hasSettingEnableLinesListTableView, hasSettingEnableMyProjects, hasSettingEnableReportListActionPlanAndClosureV1, hasSettingEnableReportListActionPlanAndClosureV2, hasSettingEnableReportPercentPassing, hasSettingEnableReports, hasSettingEnableReportTotalMiles, hasSettingFeatureClosureCopyActionPlan } from '../../../../../util/customerAppSettings/scanline';
import { getCustomer } from '../../../../../util/user';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
			[ROOT_SLICE]: _.cloneDeep(initialState)
		};
	},
  
  [setCustomer]: (state, { payload: { customer } }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        customer
			}
		};
  },
  
  [setAnalysisPageCustomer]: (state, { payload: { analysisPageCustomer } }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        analysisPageCustomer
			}
		};
  },

	[setIsLoadingLoaded]: (
		state,
		{ payload: { isLoading: newIsLoading, isLoaded: newIsLoaded } }
	) => {
		const rootSlice = selectThisSlice(state);
		const isLoading = selectIsLoading(state);
		const isLoaded = selectIsLoaded(state);

		const CHANGED = {
			isLoading: isDefined(newIsLoading) && newIsLoading !== isLoading,
			isLoaded: isDefined(newIsLoaded) && newIsLoaded !== isLoaded
		};

		if (!CHANGED.isLoaded && !CHANGED.isLoading) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoadingCustomerSettingsSource: CHANGED.isLoading ? newIsLoading : isLoading,
				isLoadedCustomerSettingsSource: CHANGED.isLoaded ? newIsLoaded : isLoaded
			}
		};
  },
  
  [setCustomerSettingsSource]: (state, { payload: { data } }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        customerSettingsSource: data
			}
		};
  },

  [replaceSettingsForCustomer]: (state) => {
    const rootSlice = selectThisSlice(state);
    const customer = selectCustomer(state);
    const source = selectCustomerSettingsSource(state) || {};
    const _settings = source[customer]?.SCANLINE || {};

    const settings = customer === getCustomer() ?
      _.cloneDeep(initialState.settings) :
      {
        enableActionPlan: hasSettingEnableActionPlan(_settings),
        enableClosureReport: hasSettingEnableClosureReport(_settings),
      
        enableLinesListCardsView: hasSettingEnableLinesListCardsView(_settings),
        enableLinesListTableView: hasSettingEnableLinesListTableView(_settings),
        enableMyProjects: hasSettingEnableMyProjects(_settings),
      
        enableReports: hasSettingEnableReports(_settings),
        enableReportListActionPlanAndClosureV1: hasSettingEnableReportListActionPlanAndClosureV1(_settings),
        enableReportListActionPlanAndClosureV2: hasSettingEnableReportListActionPlanAndClosureV2(_settings),
        enableReportPercentPassing: hasSettingEnableReportPercentPassing(_settings),
        enableReportTotalMiles: hasSettingEnableReportTotalMiles(_settings),
      
        autoRemoveIliData: hasSettingAutoRemoveIliData(_settings),
        featureClosureCopyActionPlan: hasSettingFeatureClosureCopyActionPlan(_settings),
      };
    
    return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        settings
			}
		};
  }
};

export default reducerParts;
