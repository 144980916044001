import React, { useMemo } from 'react';

import { getLocation } from '../../../scanline/utils/location';
import {
	BL_TYPES,
	selectBlType,
	selectIsButton,
	selectIsLink,
  computeClassname,
  computeTooltipTitleObject
} from './util.and.constants';

import TitleTooltip from '../TitleTooltip';
import BUTTON from './AimButton';
import ICONBUTTON from './AimIconButton';
import LINK from './AimLink';
import ICONLINK from './AimIconLink';

import './AimButtons.scss';
import './AimButtons.material.scss';

const BL_COMPONENTS_BY_TYPE = {
	[BL_TYPES.BUTTON]: BUTTON,
	[BL_TYPES.ICON_BUTTON]: ICONBUTTON,
	[BL_TYPES.LINK]: LINK,
	[BL_TYPES.ICON_LINK]: ICONLINK
};

const selectComponent = type => {
	return BL_COMPONENTS_BY_TYPE[selectBlType(type)];
};

// iconClassname, text, children
const ButtonLink = ({
  title,
  disabled,
	blType,
	iconClassname: paramiconClassname,
	bsStyle: paramBsStyle,
	className: propClassName,
	href,
	newTabTarget, // if present, will trigger buttonlink to open link in new window.
	onClick: paramOnClick,
	children,
	...remainingProps
}) => {
  const _title = useMemo(() => computeTooltipTitleObject(title, disabled), [title, disabled])
	const className = useMemo(() => computeClassname(propClassName), [
		propClassName
	]);
	const iconClassname = useMemo(
		() => paramiconClassname || 'fa fa-font-awesome',
		[paramiconClassname]
	);
	const bsStyle = useMemo(
		() => (paramBsStyle === 'link' ? 'default' : paramBsStyle),
		[paramBsStyle]
	);
	const [isButton, isLink] = useMemo(
		() => [selectIsButton(blType), selectIsLink(blType)],
		[blType]
	);
	const onClick = useMemo(() => {
		if (isLink) {
			return paramOnClick;
		}
		if (isButton) {
			if (href) {
				if (newTabTarget) {
					return e => {
						window.open(href, newTabTarget, 'noopener,noreferrer');
						if (paramOnClick) {
							paramOnClick(e);
						}
					};
				}
				return e => {
					if (paramOnClick) {
						paramOnClick(e);
					}
					getLocation().href = href;
				};
			}
		}
		return paramOnClick;
	}, [isLink, isButton, href, newTabTarget, paramOnClick]);
	const COMPONENT = useMemo(() => selectComponent(blType), [blType]);

  return (
    <TitleTooltip title={_title}>
      <COMPONENT
        className={className}
        iconClassname={iconClassname}
        disabled={disabled}
        bsStyle={bsStyle}
        {...remainingProps}
        href={isLink ? href : undefined}
        onClick={onClick}
      >
        {children}
      </COMPONENT>
    </TitleTooltip>
	);
};

export default ButtonLink;
