/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import {
	setChartState,
	destroyChartState,
	setCustomGroupRanges,
	clearCustomGroupRanges,
	setRemediationAndCustomExceptionDataStructures,
	clearRemediationAndCustomExceptionDataStructures,
	setRemediationAction,
	setRemediationActions,
  setRemediationsActions,
  removeRemediationAction
} from './actions';
import { EMPTY_OBJECT, EMPTY_ARRAY } from './constants';
import {
	createInitialChartRemediationState,
	helperCleanCustomExceptions,
	helperCleanCustomGroupRanges
} from './util';

// eslint-disable-next-line no-unused-vars
const selectHelper = (state, chartId) => {
	const { chartsRemediations = EMPTY_OBJECT } = state;

	const chartRemediations =
		chartsRemediations[chartId] || createInitialChartRemediationState();

	return [chartsRemediations, chartRemediations];
};

// ************
// PARTS
// ************
const reducerParts = {
	[setChartState]: (
		state,
		{
			payload: {
				chartId,
				remediationState = createInitialChartRemediationState()
			}
		}
	) => {
		const { chartsRemediations } = state;
		return {
			...state,
			chartsRemediations: {
				...chartsRemediations,
				[chartId]: remediationState
			}
		};
	},

	[destroyChartState]: (state, { payload: { chartId } }) => {
		const { chartsRemediations } = state;
		const newRemediations = { ...chartsRemediations };
		delete newRemediations[chartId];
		return {
			...state,
			chartsRemediations: newRemediations
		};
	},

	[setCustomGroupRanges]: (
		state,
		{ payload: { chartId, customGroupRanges = EMPTY_ARRAY } }
	) => {
		const [chartsRemediations, chartRemediations] = selectHelper(
			state,
			chartId
		);
		// debugger;
		return {
			...state,
			chartsRemediations: {
				...chartsRemediations,
				[chartId]: {
					...chartRemediations,
					customGroupRanges
				}
			}
		};
	},

	[clearCustomGroupRanges]: (state, { payload: { chartId } }) => {
		const [chartsRemediations, chartRemediations] = selectHelper(
			state,
			chartId
		);
		return {
			...state,
			chartsRemediations: {
				...chartsRemediations,
				[chartId]: {
					...chartRemediations,
					customGroupRanges: EMPTY_ARRAY
				}
			}
		};
	},

	[setRemediationAndCustomExceptionDataStructures]: (
		state,
		{
			payload: {
				chartId,
				customExceptions = EMPTY_ARRAY,
				customExceptionsMap = EMPTY_OBJECT,
				customExceptionsWithGaps = EMPTY_ARRAY,
				remediationGroupRanges = EMPTY_ARRAY,
				remediationReadings = EMPTY_ARRAY
			}
		}
	) => {
		const [chartsRemediations, chartRemediations] = selectHelper(
			state,
			chartId
		);
		return {
			...state,
			chartsRemediations: {
				...chartsRemediations,
				[chartId]: {
					...chartRemediations,
					customExceptions: helperCleanCustomExceptions(customExceptions),
					customExceptionsMap,
					customExceptionsWithGaps,
					remediationGroupRanges: helperCleanCustomGroupRanges(
						remediationGroupRanges
					),
					remediationReadings
				}
			}
		};
	},

	[clearRemediationAndCustomExceptionDataStructures]: (
		state,
		{ payload: { chartId } }
	) => {
		const [chartsRemediations, chartRemediations] = selectHelper(
			state,
			chartId
		);
		return {
			...state,
			chartsRemediations: {
				...chartsRemediations,
				[chartId]: {
					...chartRemediations,
					customExceptions: EMPTY_ARRAY,
					customExceptionsWithGaps: EMPTY_ARRAY,
					remediationGroupRanges: EMPTY_ARRAY,
					remediationReadings: EMPTY_ARRAY
				}
			}
		};
	},

	[setRemediationsActions]: (
		state,
		{ payload: { chartId, remediationsActions } }
	) => {
		const [chartsRemediations, chartRemediations] = selectHelper(
			state,
			chartId
		);
		return {
			...state,
			chartsRemediations: {
				...chartsRemediations,
				[chartId]: {
					...chartRemediations,
					remediationActions: remediationsActions
				}
			}
		};
	},

	[setRemediationActions]: (
		state,
		{ payload: { chartId, groupKey, remediationActions } }
	) => {
		const [chartsRemediations, chartRemediations] = selectHelper(
			state,
			chartId
		);
		return {
			...state,
			chartsRemediations: {
				...chartsRemediations,
				[chartId]: {
					...chartRemediations,
					remediationActions: {
						...(chartRemediations.remediationActions || {}),
						[groupKey]: remediationActions
					}
				}
			}
		};
	},

	[setRemediationAction]: (
		state,
		{ payload: { chartId, groupKey, actionKey, remediationAction } }
	) => {
		const [chartsRemediations, chartRemediations] = selectHelper(
			state,
			chartId
		);

		return {
			...state,
			chartsRemediations: {
				...chartsRemediations,
				[chartId]: {
					...chartRemediations,
					remediationActions: {
						...(chartRemediations.remediationActions || {}),
						[groupKey]: {
							...((chartRemediations.remediationActions || {})[groupKey] || {}),
							[actionKey]: remediationAction
						}
					}
				}
			}
		};
  },

  
	[removeRemediationAction]: (
		state,
		{ payload: { chartId, groupKey, actionKey, remediationAction } }
  ) => {
    if (!actionKey && !remediationAction?.key) { 
      debugger;
      return state;
    }

    debugger;

		const [chartsRemediations, chartRemediations] = selectHelper(
			state,
			chartId
    );
    
    console.log({ chartId, groupKey, actionKey, remediationAction });
    const updatedRemediationActions = { ...((chartRemediations.remediationActions || {})[groupKey] || {}) };
    console.log(updatedRemediationActions);
    const key = actionKey || remediationAction?.key;
    delete updatedRemediationActions[key];
    console.log(updatedRemediationActions);
    debugger;

		return {
			...state,
			chartsRemediations: {
				...chartsRemediations,
				[chartId]: {
					...chartRemediations,
					remediationActions: {
						...(chartRemediations.remediationActions || {}),
						[groupKey]: updatedRemediationActions
					}
				}
			}
		};
	}
};

export default reducerParts;
