/* eslint-disable import/prefer-default-export */
import { ROOT_SLICE_ADMIN } from '../constants';

export const selectRootSliceAdmin = state => { 
  if (state[ROOT_SLICE_ADMIN]) { 
    return state[ROOT_SLICE_ADMIN];
  }

  // else assume state is already lines aggs.
  return state;

}


