export const BL_TYPES = {
	BUTTON: 'BUTTON',
	ICON_BUTTON: 'ICON_BUTTON',
	LINK: 'LINK',
	ICON_LINK: 'ICON_LINK'
};

const isDefined = v => v !== undefined && v !== null;

export const selectBlType = type => {
	const _type = type ? type.toUpperCase() : '';
	return BL_TYPES[_type] || BL_TYPES.BUTTON;
};

export const selectIsButton = type => {
	const _type = selectBlType(type);
	return _type === BL_TYPES.BUTTON || _type === BL_TYPES.ICON_BUTTON;
};

export const selectIsLink = type => {
	const _type = selectBlType(type);
	return _type === BL_TYPES.LINK || _type === BL_TYPES.ICON_LINK;
};

export const computeClassname = (className = '') => {
	const paramParts = className.split(' ').filter(v => v);
	const parts = [...new Set(['aim-button', ...paramParts])];
	return parts.join(' ');
};

const computeTooltipDelayShow = (delayShow, buttonDisabled) => { 
  if (isDefined(delayShow)) { 
    return delayShow;
  }

  return buttonDisabled ? 200 : 1300;
}

export const computeTooltipTitleObject = (title, buttonDisabled) => { 
  if (!isDefined(title)) { 
    return {};
  }

  if (typeof title === 'string') { 
    return { text: title, delayShow: computeTooltipDelayShow(null, buttonDisabled) };
  }

  const props = { ...title };
  props.delayShow = computeTooltipDelayShow(props.delayShow, buttonDisabled);
  return props;
}
