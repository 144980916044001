/* eslint-disable import/no-cycle */
import {
	setCustomer,
	setRestrictionDefinitions,
	setIsLoadingLoadedRestrictionDefinitions,
	setHasLoadingErrorRestrictionDefinitions
} from './actions';
import { ROOT_SLICE } from './constants';
import {
	selectThisSlice,
	selectIsLoadingRestrictionDefinitions,
	selectIsLoadedRestrictionDefinitions,
	selectHasLoadingErrorRestrictionDefinitions
} from './selectors';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[setCustomer]: (state, { payload: { customer } }) => {
		const slice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...slice,
				customer
			}
		};
	},
	[setRestrictionDefinitions]: (
		state,
		{
			payload: {
				restrictionDefinitions,
				mapRestrictionsByGroupName,
				listRestrictionDefinitions,
				mapRestrictionsListByAppKey
			}
		}
	) => {
		const slice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...slice,
				restrictionDefinitions,
				mapRestrictionsByGroupName,
				listRestrictionDefinitions,
				mapRestrictionsListByAppKey
			}
		};
	},

	[setHasLoadingErrorRestrictionDefinitions]: (
		state,
		{ payload: { hasError } }
	) => {
		const slice = selectThisSlice(state);
		const currentHasError = selectHasLoadingErrorRestrictionDefinitions(state);

		if (hasError === currentHasError) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...slice,
				hasLoadingErrorRestrictionDefinitions: hasError
			}
		};
	},

	[setIsLoadingLoadedRestrictionDefinitions]: (
		state,
		{
			payload: {
				isLoadingRestrictionDefinitions: newIsLoadingRestrictionDefinitions,
				isLoadedRestrictionDefinitions: newIsLoadedRestrictionDefinitions
			}
		}
	) => {
		const slice = selectThisSlice(state);
		const isLoadingRestrictionDefinitions = selectIsLoadingRestrictionDefinitions(
			state
		);
		const isLoadedRestrictionDefinitions = selectIsLoadedRestrictionDefinitions(
			state
		);

		const isLoadingChanged =
			isDefined(newIsLoadingRestrictionDefinitions) &&
			newIsLoadingRestrictionDefinitions !== isLoadingRestrictionDefinitions;
		const isLoadedChanged =
			isDefined(newIsLoadedRestrictionDefinitions) &&
			newIsLoadedRestrictionDefinitions !== isLoadedRestrictionDefinitions;

		if (!isLoadingChanged && !isLoadedChanged) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...slice,
				isLoadingRestrictionDefinitions: isLoadingChanged
					? newIsLoadingRestrictionDefinitions
					: isLoadingRestrictionDefinitions,
				isLoadedRestrictionDefinitions: isLoadedChanged
					? newIsLoadedRestrictionDefinitions
					: isLoadedRestrictionDefinitions
			}
		};
	}
};

export default reducerParts;
