export const TYPE_TROUBLESHOOTING = 'Troubleshooting'
export const TYPE_RECTIFIER = 'Rectifier Adjustments';
export const TYPE_GROUNDBEDS = 'New Groundbeds & Replacements';
export const TYPE_RECOATS = 'Recoats';
export const TYPE_REPAIRS = 'Repairs';
export const TYPE_MITIGATION = 'Other Mitigation Steps';
export const TYPE_NO_ACTION = 'No Action Required'

export const RemediationActionTypes = [TYPE_TROUBLESHOOTING, TYPE_GROUNDBEDS, TYPE_RECOATS, TYPE_REPAIRS, TYPE_MITIGATION, TYPE_NO_ACTION];

const STATUS_NEW = 'New';
const STATUS_IN_PROGRESS = 'In Progress';
const STATUS_ON_HOLD = 'On Hold';
const STATUS_COMPLETE = 'Complete';
const STATUS_CLOSED_OUT = 'Closed Out';

const DEFAULT_STATUSES = [
  STATUS_NEW,
  STATUS_IN_PROGRESS,
  STATUS_ON_HOLD,
  STATUS_COMPLETE,
  STATUS_CLOSED_OUT
];

export const RemediationActionFields = {
    [TYPE_TROUBLESHOOTING]: {
        title: 'Troubleshooting',
        subTitle: '(document troubleshooting steps performed when trying to identify cause of low potential area)',
        field: {
            name: 'actionPlan.troubleshooting',
            placeholder: 'Fill as required',
        }
    },

    
    [TYPE_GROUNDBEDS]: {
        title: 'New Groundbeds &amp; Groundbed Replacements',
        subTitle: '(specify line and approximate location)',
        field: {
            name: 'actionPlan.replacements',
            placeholder: 'Fill as required',
        }
    },
    
    [TYPE_RECOATS]: {
        title: 'Recoats',
        subTitle: '(specify line, length and approximate MP/MLV/Series and Stationing)',
        field: {
            name: 'actionPlan.recoats',
            placeholder: 'Fill as required',
        }
    },
    
    [TYPE_REPAIRS]: {
        title: 'Repairs',
        subTitle: '(Broken test leads, malfunctioning rectifiers, malfunctioning isolation, damaged bonds, etc.)',
        field: {
            name: 'actionPlan.repairs',
            placeholder: 'Fill as required',
        }
    },
    
    [TYPE_MITIGATION]: {
        title: 'Other Mitigation Steps',
        subTitle: '(AC Mitigation, DC Stray Current Mitigation, electrical isolation, etc.)',
        field: {
            name: 'actionPlan.other',
            placeholder: 'Fill as required',
        }
    },
    
    [TYPE_NO_ACTION]: {
        title: 'No Action Required',
        subTitle: '(Foreign rectifier not interrupted, etc.)',
        field: {
            name: 'actionPlan.noAction',
            placeholder: 'Fill as required',
        }
    }
}

export const RemediationActions = {
    // TYPE_TROUBLESHOOTING
    'TSRA': {
      name: 'Adjust Rectifier',
      type: TYPE_TROUBLESHOOTING,
      statuses: DEFAULT_STATUSES
    },

    'TSCT': {
      name: 'Coating Test',
      type: TYPE_TROUBLESHOOTING,
      statuses: DEFAULT_STATUSES
    },  

    'TSRV': {
      name: 'Reading Validation',
      type: TYPE_TROUBLESHOOTING,
      statuses: DEFAULT_STATUSES
    },

    'TSIT': {
      name: 'Isolation Testing',
      type: TYPE_TROUBLESHOOTING,
      statuses: DEFAULT_STATUSES
    },



    // TYPE_GROUNDBEDS
    'GROU': {
        name: 'Groundbeds',
        type: TYPE_GROUNDBEDS,
        statuses: DEFAULT_STATUSES
    },



    // TYPE_RECOATS 
    'RECO': {
        name: 'Recoats',
        type: TYPE_RECOATS,
        statuses: DEFAULT_STATUSES
    },



    // TYPE_REPAIRS 
    'BRTL': {
        name: 'Broken test leads',
        type: TYPE_REPAIRS,
        statuses: DEFAULT_STATUSES
    },
    'MARE': {
        name: 'Malfunctioning rectifiers',
        type: TYPE_REPAIRS,
        statuses: DEFAULT_STATUSES
    },
    'MAIS': {
        name: 'Malfunctioning isolation',
        type: TYPE_REPAIRS,
        statuses: DEFAULT_STATUSES
    },
    'DABO': {
        name: 'Damaged bonds',
        type: TYPE_REPAIRS,
        statuses: DEFAULT_STATUSES
    },

    

    // TYPE_MITIGATION
    'ACMI': {
        name: 'AC Mitigation',
        type: TYPE_MITIGATION,
        statuses: DEFAULT_STATUSES
    },
    'DSCM': {
        name: 'DC Stray Current Mitigation',
        type: TYPE_MITIGATION,
        statuses: DEFAULT_STATUSES
    },
    'ELIS': {
        name: 'Electrical isolation',
        type: TYPE_MITIGATION,
        statuses: DEFAULT_STATUSES
    },



    // TYPE_NO_ACTION
    'FRNI': {
        name: 'Foreign Rectifier Not Interupted',
        type: TYPE_NO_ACTION,
        statuses: DEFAULT_STATUSES
    }
} 

export const RemediationActionsKeys = Object.keys(RemediationActions);

export const LIST_FILTER_REMEDIATION_STATUSES = DEFAULT_STATUSES;
export const LIST_FILTER_REMEDIATION_ACTIONS = RemediationActionsKeys.map(key => ({
  ...RemediationActions[key],
  key
}));