import { getCustomer } from "../../../../../util/user";
import {
  COLUMN_KEYS,
  AEGION_ONLY_COLUMN_KEYS,
  REQUIRED_COLUMN_KEYS,
  DEFAULT_COLUMN_KEYS,
  DEFAULT_COLUMN_KEYS_SORT_LIST
} from "./constants";

const sessionCustomer = getCustomer();


/**
 * HELPERS
 */

const isDefined = v => v !== undefined && v !== null;

const isRequiredColumn = (key) => !!REQUIRED_COLUMN_KEYS[key];

const canUseColumn = (key) => (sessionCustomer === 'Aegion' || !AEGION_ONLY_COLUMN_KEYS[key]) && !!COLUMN_KEYS[key];

const hasColumn = (columnMap, key) => !!columnMap[key];

/**
 * TOGGLE COLUMN
 */

const enableColumn = (columnMap, key) => {
  if (!canUseColumn(key) || hasColumn(columnMap, key)) {
    return columnMap;
  }

  return {
    ...columnMap,
    [key]: key
  }
};

const disableColumn = (columnMap, key) => {
  if (!isRequiredColumn(key) && hasColumn(columnMap, key)) { 
    const clone = { ...columnMap };
    delete clone[key];
    return clone;
  }

  return columnMap;
}

const toggleColumn = (columnMap, key) => {
  if (hasColumn(columnMap, key)) {
    return disableColumn(columnMap, key);
  }

  return enableColumn(columnMap, key);
}

/**
 * CHANGE SORT POSITION
 */

const protectedIdx = (columnSortList, key, toIdx) => { 
  if (!isDefined(toIdx)) {
    return Math.max(1, columnSortList.length - 1);
  }
  if (key !== COLUMN_KEYS.LINE && toIdx < 1) {
    return 1;
  }
  if (toIdx < 0) { 
    return 0;
  }
  if (toIdx > columnSortList.length - 1) {
    return Math.max(1, columnSortList.length - 1);
  }
  return toIdx;
}

const moveColumnSort = (columnSortList, key, toIdx) => {
  const _toIdx = protectedIdx(columnSortList, key, toIdx);
  let newList = columnSortList.filter(k => k !== key);
  newList = [...newList.slice(0, _toIdx), key, ...newList.slice(_toIdx)];
  return newList;
}

/**
 * MERGE SERVER STATE WITH CLIENT STATE
 */

const replaceDefaultColumnKeys = (columnKeys = {}) => {
  const newColumnKeys = { ...columnKeys };

  if (Object.keys(newColumnKeys).length === 0) {
    return { ...DEFAULT_COLUMN_KEYS }
  }

  if (sessionCustomer !== 'Aegion') {
    // remove AEGION_ONLY_COLUMN_KEYS
    Object.keys(AEGION_ONLY_COLUMN_KEYS).forEach(key => {
      delete newColumnKeys[key];
    });
  }

  // Add Missing REQUIRED_COLUMN_KEYS
  Object.keys(REQUIRED_COLUMN_KEYS).forEach(key => {
    if (!newColumnKeys[key]) {
      newColumnKeys[key] = key;
    }
  });

  return newColumnKeys;
};

const replaceDefaultColumnKeySortList = (columnKeysSortList) => {
  if (!columnKeysSortList) {
    return [...DEFAULT_COLUMN_KEYS_SORT_LIST];
  }

  const missingKeys = {...COLUMN_KEYS};
  const newList = columnKeysSortList.filter(key => !!COLUMN_KEYS[key]);
  newList.forEach(key => { 
    delete missingKeys[key]
  });

  DEFAULT_COLUMN_KEYS_SORT_LIST.forEach(key => { 
    if (missingKeys[key]) {
      newList.push(key);
    }
  })

  return newList
}

/**
 * EXPORTS
 */

export { 
  isRequiredColumn,

  enableColumn,
  disableColumn,
  toggleColumn,

  moveColumnSort,

  replaceDefaultColumnKeys,
  replaceDefaultColumnKeySortList
}

export default {
  isRequiredColumn, 

  enableColumn,
  disableColumn,
  toggleColumn,

  moveColumnSort,

  replaceDefaultColumnKeys,
  replaceDefaultColumnKeySortList
}