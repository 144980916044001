/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { DEFAULT_FILTER_KEYS, DEFAULT_FILTER_KEYS_SORT_LIST } from '../constants';
import { fetchPercentPassingFilterSettings, postPercentPassingFilterSettings } from '../../../api/percentpassingfiltersettings';
import { selectFilterKeys, selectFilterKeysSortList } from '../selectors';
import {
  setSettings,
  setIsLoadingLoaded,
  clear,
  resetToDefaults,
  enableFilter,
  disableFilter,
  toggleFilter,
  moveFilterSort
} from './actions';

const isEmpty = obj => {
  if (obj instanceof Array) {
    return obj.length === 0;
  }

  if (typeof obj === 'object') { 
    return Object.keys(obj).length === 0;
  }

  return !obj;
}

const thunkLoad = () => (dispatch) => {
  dispatch(clear());
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
  fetchPercentPassingFilterSettings().then((res) => {
    if (!isEmpty(res)) { 
      const { filterKeys, filterKeysSortList } = res;
      dispatch(
        setSettings(
          filterKeys || {...DEFAULT_FILTER_KEYS},
          filterKeysSortList || [...DEFAULT_FILTER_KEYS_SORT_LIST]      
        )
      );
    }
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

const thunkSave = () => (_dispatch, getState) => {
  const filterKeys = selectFilterKeys(getState());
  const filterKeysSortList = selectFilterKeysSortList(getState());
  postPercentPassingFilterSettings(filterKeys, filterKeysSortList);
};

const thunkEnableFilter = (key) => (dispatch) => {
  dispatch(enableFilter(key));
  dispatch(thunkSave());
};

const thunkDisableFilter = (key) => (dispatch) => { 
  dispatch(disableFilter(key));
  dispatch(thunkSave());
};

const thunkToggleFilter = (key) => (dispatch) => { 
  dispatch(toggleFilter(key));
  dispatch(thunkSave());
};

const thunkMoveFilterSort = (key, toIdx) => (dispatch) => { 
  dispatch(moveFilterSort(key, toIdx));
  dispatch(thunkSave());
};

const thunkResetToDefaults = () => (dispatch) => { 
  dispatch(resetToDefaults());
  dispatch(thunkSave());
}



export {
  thunkLoad,
  thunkSave,

  thunkResetToDefaults,

  thunkEnableFilter,
  thunkDisableFilter,
  thunkToggleFilter,
  thunkMoveFilterSort
};
