/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
  setSurveys,
  setReadings,
  setFlatReadings, 
  setSelectedReading, 
  setSelectedSurvey, 
  setMapLines, 
  setStartReadingsEndReadings, 
  setFocusedChart, 
  setChartsBcSeriesDs,
  clear
} = createActions(
	{
    SET_SURVEYS: (surveys) => ({ surveys }),
    SET_READINGS: (readingsWithGeoGaps, readingsWithGeoGapsIndexMap) => ({ readingsWithGeoGaps, readingsWithGeoGapsIndexMap }),
    SET_FLAT_READINGS: (flattenedReadings, flattenedReadingsIndexMap) => ({ flattenedReadings, flattenedReadingsIndexMap }),
    SET_SELECTED_READING: (selectedReading, selectedReadingSurvey) => ({ selectedReading, selectedReadingSurvey }),
    SET_SELECTED_SURVEY: (selectedReadingSurvey) => ({ selectedReadingSurvey }),
    SET_MAP_LINES: (mapLines) => ({ mapLines }),
    SET_START_READINGS_END_READINGS: (startReadings, endReadings) => ({ startReadings, endReadings }),
    SET_FOCUSED_CHART: (focusedChart) => ({ focusedChart }),
    SET_CHARTS_BC_SERIES_DS: (chartsBcSeriesDs) => ({chartsBcSeriesDs}),
		CLEAR: () => ({})
	},
	{ prefix: ROOT_SLICE }
);

export {
  setSurveys,
  setReadings,
  setFlatReadings, 
  setSelectedReading, 
  setSelectedSurvey, 
  setMapLines, 
  setStartReadingsEndReadings, 
  setFocusedChart, 
  setChartsBcSeriesDs,
  clear
};
