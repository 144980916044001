import { getLocation, parseQueryString } from '../../../../../utils/location';

// eslint-disable-next-line import/no-cycle
import { selectFilterItem } from '../../LinkSurveysFilters/actions';
import { selectPrimarySurveys } from '../../../../../reducers/selectors/alignedReadings';
import { selectFilteredLinesAggs } from '../../LinkSurveysFiltersUI/selectors';
import { FILTER_KEYS } from '../../LinkSurveysFilters/util.selectedFilters';
import { selectIsFilterEnabled } from '../../LinkSurveysFilterSettings/selectors';
import { expandAllRows } from './actions';
import { selectPipesFilterItems } from '../../LinkSurveysFilters/selectors';

const getLineName = () => {
  const location = getLocation();
  if (!location) {
    return null;
  }

  const { linename, project } = parseQueryString(location.search);
  return !linename ? project : linename;
}

const thunkSetSmartDefaultFilterSelections = () => (dispatch, getState) => {
  const lineName = getLineName();

  if (!lineName) { 
    return;
  }

  const isPipeFilterEnabled = selectIsFilterEnabled(getState(), FILTER_KEYS.PIPES);
  if (!isPipeFilterEnabled) { 
    return;
  }

  const state = getState();
  const stateApp = state.app || state;
  const linesAggs = selectFilteredLinesAggs(state);
  const primarySurveys = selectPrimarySurveys(stateApp);
  const primarySurvey = (primarySurveys || [])[0];
  const pipeFilters = selectPipesFilterItems(state);

  if (primarySurvey) {
    const id = primarySurvey.internalId;
    if (id) {
      const matchingLinesAggs = linesAggs.filter(la => la.lineName === lineName);
      const matchingPipeIds = new Set([]);
      matchingLinesAggs.forEach(la => {
        (la.jobs || []).forEach(job => {
          if (job.pipeId) {
            matchingPipeIds.add(job.pipeId)
          }
        })
      });
      const matchingPipesFilters = pipeFilters.filter((pFilter) => { 
        return matchingPipeIds.has(pFilter.id);
      });
      matchingPipesFilters.forEach(pipe => {
        dispatch(selectFilterItem(FILTER_KEYS.PIPES, pipe));
      });
      if (matchingPipesFilters && matchingPipesFilters.length) {
        dispatch(expandAllRows());
      }
    }
  }
}

export default thunkSetSmartDefaultFilterSelections;
