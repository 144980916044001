/* eslint-disable import/no-named-as-default-member */
import { selectRootSliceLPR } from '../../Main/selectors';
import { ROOT_SLICE } from './constants';

export const selectThisSlice = state => { 
  const slice = selectRootSliceLPR(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already reports aggs editor.
  return state;
};

export const selectIsEditorOpen = state => {
  const slice = selectThisSlice(state);
  const { isEditorOpen } = slice;
	return isEditorOpen;
};

export const selectSelectedItem = state => {
  const slice = selectThisSlice(state);
  const { selectedItem } = slice;
	return selectedItem;
};

export const selectData = (state) => {
  const slice = selectThisSlice(state);
  const { data } = slice;
	return data;
};

export const selectIsDataLoading = state => {
	const slice = selectThisSlice(state);
	const { isDataLoading } = slice;
	return isDataLoading;
};

export const selectIsDataLoaded = state => {
	const slice = selectThisSlice(state);
	const { isDataLoaded } = slice;
	return isDataLoaded;
};

export const selectErrorMessage = state => {
	const slice = selectThisSlice(state);
	const { errorMessage } = slice;
	return errorMessage;
};