/* eslint-disable import/prefer-default-export */
export const FILTER_KEYS = {
  CUSTOMERS: 'customers',
  ENTITIES: 'entities',
  AREAS: 'areas',
  PIPES: 'pipes',
  REGULATORY_STATUSES: 'regulatoryStatuses',
  JOBS: 'jobs',
  SURVEY_TYPES: 'surveyTypes',
  PMS: 'pms',
  CTS: 'cts'
};

const FILTER_ITEM_ID_KEY = {
  [FILTER_KEYS.CUSTOMERS]: 'id',
  [FILTER_KEYS.ENTITIES]: 'id',
  [FILTER_KEYS.AREAS]: 'id',
  [FILTER_KEYS.PIPES]: 'id',
  [FILTER_KEYS.REGULATORY_STATUSES]: 'id',
  [FILTER_KEYS.JOBS]: 'jobId',
  [FILTER_KEYS.SURVEY_TYPES]: 'id',
  [FILTER_KEYS.PMS]: 'pmId',
  [FILTER_KEYS.CTS]: 'ctId'
}

const getId = (filterKey, item) => {
  const idKey = FILTER_ITEM_ID_KEY[filterKey];
  return idKey ? item[idKey] : item;
}

const createSelectFilterObj = (filterKey, item) => {
  return { filterKey, item }
};

export const addSelectedFilter = (
  filterKey,
  item,
  {
    selectedFiltersArr: paramSelectedFiltersArr = [],
    selectedFiltersArrByFilterKey: paramSelectedFiltersArrByFilterKey = {},
    selectedFiltersMap: paramSelectedFiltersMap = {}
  } = {}) => { 
  const selectedFiltersArr = [...paramSelectedFiltersArr];
  const selectedFiltersArrByFilterKey = { ...paramSelectedFiltersArrByFilterKey };
  const selectedFiltersMap = { ...paramSelectedFiltersMap };
  const newFilter = createSelectFilterObj(filterKey, item);

  selectedFiltersArr.push(newFilter);

  selectedFiltersArrByFilterKey[filterKey] = [...(selectedFiltersArrByFilterKey[filterKey] || [])];
  selectedFiltersArrByFilterKey[filterKey].push(newFilter);


  selectedFiltersMap[filterKey] = {
    ...(selectedFiltersMap[filterKey] || {}),
    [getId(filterKey, item)]: newFilter
  }

  return { selectedFiltersArr, selectedFiltersArrByFilterKey, selectedFiltersMap };
}

export const removeSelectedFilter = (
  filterKey,
  item,
  {
    selectedFiltersArr: paramSelectedFiltersArr = [],
    selectedFiltersArrByFilterKey: paramSelectedFiltersArrByFilterKey = {},
    selectedFiltersMap: paramSelectedFiltersMap = {}
  } = {}
) => { 

  const selectedFiltersArr = paramSelectedFiltersArr
    .filter((fObj) => fObj.filterKey !== filterKey || getId(fObj.filterKey, fObj.item) !== getId(filterKey, item));

  const selectedFiltersArrByFilterKey = { ...paramSelectedFiltersArrByFilterKey };
  selectedFiltersArrByFilterKey[filterKey] = selectedFiltersArrByFilterKey[filterKey]
    .filter((fObj) => fObj.filterKey !== filterKey || getId(fObj.filterKey, fObj.item) !== getId(filterKey, item));
  
  const selectedFiltersMap = { ...(paramSelectedFiltersMap || {}) };
  selectedFiltersMap[filterKey] = { ...(selectedFiltersMap[filterKey]) };
  delete selectedFiltersMap[filterKey][getId(filterKey, item)];
  if (Object.keys(selectedFiltersMap[filterKey]).length === 0) { 
    delete selectedFiltersMap[filterKey];
  }

  return { selectedFiltersArr, selectedFiltersArrByFilterKey, selectedFiltersMap };
}

export const computeIsFilterItemSelected = (filterKey, item, selectedFiltersMap) => { 
  const id = getId(filterKey, item)
  const map = selectedFiltersMap[filterKey] || [];
  return !!map[id];
}

export default {
  FILTER_KEYS,
  getFilterItemId: getId,
  addSelectedFilter,
  removeSelectedFilter,

  computeIsFilterItemSelected
}