const { createActions } = require('redux-actions');
const { ROOT_SLICE } = require('../constants');

const { setSorting } = createActions(
	{
		SET_SORTING: ({ sortColumn, sortDirection }) => ({
			sortColumn,
			sortDirection
		})
	},
	{ prefix: ROOT_SLICE }
);

export { setSorting };
