import { DEFAULT_FILTER_KEYS, DEFAULT_FILTER_KEYS_SORT_LIST } from "./constants";

export const initialState = {
  filterKeys: {...DEFAULT_FILTER_KEYS},
  filterKeysSortList: [...DEFAULT_FILTER_KEYS_SORT_LIST],
	isLoading: false,
	isLoaded: false,
  errorMessage: undefined,
};

export const stateChangesForReset = {
	isLoading: initialState.isLoading,
	isLoaded: initialState.isLoaded,
  errorMessage: initialState.errorMessage
}

export default initialState;
