/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
import { ROOT_SLICE, DEFAULT_COLUMN_KEYS, DEFAULT_COLUMN_KEYS_SORT_LIST } from './constants';
import { stateChangesForReset } from './initialState';
import {
  setSettings,
  setIsLoadingLoaded,
  setErrorMessage,
  clear,
  resetToDefaults,

  enableColumn,
  disableColumn,
  toggleColumn,

  moveColumnSort
} from './actions';
import {
	selectThisSlice,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage,
  selectColumnKeys,
  selectColumnKeysSortList
} from './selectors';
import UTIL from './util';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
      [ROOT_SLICE]: {
        ...state[ROOT_SLICE],
        ...stateChangesForReset
      }
		};
  },
  
  [resetToDefaults]: (state) => { 
    const rootSlice = selectThisSlice(state);
    return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        columnKeys: { ...DEFAULT_COLUMN_KEYS },
        columnKeysSortList: [...DEFAULT_COLUMN_KEYS_SORT_LIST]
			}
    };
  },

	[setSettings]: (state, { payload: { columnKeys, columnKeysSortList } }) => {
    const rootSlice = selectThisSlice(state);
    return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,

        columnKeys: columnKeys ?
          UTIL.replaceDefaultColumnKeys(columnKeys) :
          { ...DEFAULT_COLUMN_KEYS },

        columnKeysSortList: columnKeysSortList ?
          UTIL.replaceDefaultColumnKeySortList(columnKeysSortList) :
          [...DEFAULT_COLUMN_KEYS_SORT_LIST]
			}
    };
  },

	[setIsLoadingLoaded]: (
		state,
		{ payload: { isLoading: newIsLoading, isLoaded: newIsLoaded } }
	) => {
		const rootSlice = selectThisSlice(state);
		const isLoading = selectIsLoading(state);
		const isLoaded = selectIsLoaded(state);

		const CHANGED = {
			isLoading: isDefined(newIsLoading) && newIsLoading !== isLoading,
			isLoaded: isDefined(newIsLoaded) && newIsLoaded !== isLoaded
		};

		if (!CHANGED.isLoaded && !CHANGED.isLoading) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoading: CHANGED.isLoading ? newIsLoading : isLoading,
				isLoaded: CHANGED.isLoaded ? newIsLoaded : isLoaded
			}
		};
	},

	[setErrorMessage]: (
		state,
		{ payload: { errorMessage: newErrorMessage } }
	) => {
		const rootSlice = selectThisSlice(state);
		const errorMessage = selectErrorMessage(state);

		if (newErrorMessage === errorMessage) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				errorMessage: newErrorMessage
			}
		};
  },

  [enableColumn]: (
		state,
		{ payload: { key } }
	) => {
    const rootSlice = selectThisSlice(state);
    const columnKeys = selectColumnKeys(state);

		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        columnKeys: UTIL.enableColumn(columnKeys, key)
			}
		};
  },

  [disableColumn]: (
		state,
		{ payload: { key } }
	) => {
		const rootSlice = selectThisSlice(state);
    const columnKeys = selectColumnKeys(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        columnKeys: UTIL.disableColumn(columnKeys, key)
			}
		};
  },

  [toggleColumn]: (
		state,
		{ payload: { key } }
	) => {
		const rootSlice = selectThisSlice(state);
    const columnKeys = selectColumnKeys(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        columnKeys: UTIL.toggleColumn(columnKeys, key)
			}
		};
  },

  [moveColumnSort]: (
		state,
		{ payload: { key, toIdx } }
	) => {
    const rootSlice = selectThisSlice(state);
    const columnKeysSortList = selectColumnKeysSortList(state);

		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        columnKeysSortList: UTIL.moveColumnSort(columnKeysSortList, key, toIdx)
			}
		};
  },
};

export default reducerParts;
