/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
  setCustomerSettingsSource, 
  replaceSettingsForCustomer, 
  setCustomer, // intended for use with customer dropdown on listings page, Aegion users only
  setAnalysisPageCustomer,
  setIsLoadingLoaded, 
  clear
} = createActions(
	{
    SET_CUSTOMER_SETTINGS_SOURCE: (data) => ({ data }),
    REPLACE_SETTINGS_FOR_CUSTOMER: () => ({}),
    SET_CUSTOMER: (customerName) => ({ customer: customerName }),
    SET_ANALYSIS_PAGE_CUSTOMER: (customerName) => ({ analysisPageCustomer: customerName }),
		SET_IS_LOADING_LOADED: ({ isLoading, isLoaded }) => ({
			isLoading,
			isLoaded
		}),
		CLEAR: () => ({})
	},
	{ prefix: ROOT_SLICE }
);

export {
  setCustomerSettingsSource, 
  replaceSettingsForCustomer, 
  setCustomer, 
  setAnalysisPageCustomer,
  setIsLoadingLoaded, 
  clear,
};
