import React, { useMemo } from 'react';
import { computeClassname, computeTooltipTitleObject } from './util.and.constants';

import TitleTooltip from '../TitleTooltip';

import './AimButtons.scss';
import './AimButtons.material.scss';

const AimIconLink = ({
	children,
  title,
  disabled,
	className: propClassName,
	bsStyle = 'primary',
	iconClassname,
	underlineOnHover = false,
	...remainingProps
}) => {
  const _title = useMemo(() => computeTooltipTitleObject(title, disabled), [title, disabled])
	const className = useMemo(
		() =>
			computeClassname(
				`a-${bsStyle} ${propClassName} ${
					underlineOnHover ? 'underline-on-hover' : ''
				}  ${disabled ? 'disabled' : ''}`
			),
		[bsStyle, underlineOnHover, disabled, propClassName]
	);
  return (
    <TitleTooltip title={_title}>
      <a className={className} disabled={disabled} {...remainingProps}>
        {children}
        {children ? (
          <i className={`button-link-ico ${iconClassname}`} />
        ) : (
          <i className={`button-link-ico no-margin ${iconClassname}`} />
        )}
      </a>
    </TitleTooltip>
	);
};

export default AimIconLink;
