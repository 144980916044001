export default {
	"PLATFORM": {
		"name": "Platform",
		"abbreviation": "PLTFM",
		"order": 1,
		"permissions": {
			"AEGION_DATAPROCESSOR": {
				"name": "Aegion Dataprocessor",
				"abbreviation": "ADP",
				"description": "An Aegion user with permissions to perform any Dataprocessor tasks.",
				"awsGroupName": "Role:DP",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"aegionOnly": true,
				"devOnly": true,
				"order": 1
			},
			"AEGION_PROJECT_MANAGER": {
				"name": "Aegion Project Manager",
				"abbreviation": "APM",
				"description": "An Aegion user with permissions to perform any Project Manager tasks.",
				"awsGroupName": "Role:PM",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"aegionOnly": true,
				"devOnly": true,
				"order": 2
			},
			"CUSTOMER_CORROSION_TECHNICIAN": {
				"name": "Customer Corrosion Technician",
				"abbreviation": "CCT",
				"description": "A customer user with permissions to perform any customer Corrosion Technician tasks.",
				"awsGroupName": "CorrosionTechnician",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 3
			},
			"CUSTOMER_PROJECT_MANAGER": {
				"name": "Customer Project Manager",
				"abbreviation": "CPM",
				"description": "A customer user with permissions to perform any customer Project Manager tasks.",
				"awsGroupName": "ProjectManager",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 4
			},
			"CUSTOMER_ADMINS": {
				"name": "Customer Admins",
				"abbreviation": "CADM",
				"description": "Customer user accounts with ability to manage employee accounts through customer admin.",
				"awsGroupName": "CustomerAdmins",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 5
			},
			"AIP_ADMINS": {
				"name": "AIP Admins",
				"abbreviation": "AADM",
				"description": "Aegion user accounts with super privileges.",
				"awsGroupName": "AIPAdmins",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"aegionOnly": true,
				"order": 6
			},
			"AIP_POWER_USER": {
				"name": "AIP Power User",
				"abbreviation": "APWU",
				"description": "Aegion user accounts with ability to delete data from AIM.",
				"awsGroupName": "Permission:AIP.Power.User",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"aegionOnly": true,
				"order": 7
			}
		}
	},
	"CISVIEW": {
		"name": "CISView",
		"abbreviation": "CISV",
		"order": 2,
		"permissions": {
			"VIEWER": {
				"name": "Viewer",
				"abbreviation": "VWR",
				"description": "Read only access. Can open tabs, view files, and download them.",
				"awsGroupName": "Permission:Cisv.Viewer",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"devOnly": true,
				"order": 1
			},
			"PROCESSOR": {
				"name": "Processor",
				"abbreviation": "PRC",
				"description": "Read / write access. Access to core CISView features.",
				"awsGroupName": "Role:DP",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"aegionOnly": true,
				"order": 2
			},
			"MANAGER": {
				"name": "CISView Manager",
				"abbreviation": "MGR",
				"description": "Processor Access + ability to approve data and a few higher operations",
				"awsGroupName": "Role:PM",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"aegionOnly": true,
				"order": 3
			},
			"ADMINISTRATOR": {
				"name": "Administrator",
				"abbreviation": "ADM",
				"description": "CISView Manager + ability to add customers and other special operations.",
				"awsGroupName": "Permission:Cisv.Administrator",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"devOnly": true,
				"order": 4
			},
			"SYSTEM_ADMINISTRATOR": {
				"name": "System Administrator",
				"abbreviation": "SYSA",
				"description": "Full access - AIM team members",
				"awsGroupName": "Permission:Cisv.System_Administrator",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"devOnly": true,
				"order": 5
			}
		}
	},
	"CORRELATOR": {
		"name": "Correlator",
		"abbreviation": "COR",
		"order": 4,
		"permissions": {
			"TECHNICIAN": {
				"name": "Technician",
				"abbreviation": "TCH",
				"description": "Only Tablet Access",
				"awsGroupName": "Permission:Cor.Technician",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 1,
				"devOnly": true
			},
			"VIEWER": {
				"name": "Survey Viewer",
				"abbreviation": "VWR",
				"description": "Read only access to web interface.",
				"awsGroupName": "Permission:Cor.Survey_Viewer",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 2,
				"devOnly": true
			},
			"MANAGER": {
				"name": "Survey Manager",
				"abbreviation": "MGR",
				"description": "Survey Viewer + ability to build surveys and make minor data edits.",
				"awsGroupName": "Correlator:SurveyManager",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 3
			},
			"ADMINISTRATOR": {
				"name": "Administrator",
				"abbreviation": "ADM",
				"description": "Survey Manager + ability to modify and delete data.",
				"awsGroupName": "Correlator:Administrators",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 4
			},
			"SYSTEM_ADMINISTRATOR": {
				"name": "System Administrator",
				"abbreviation": "SYSA",
				"description": "Full access - subset of AIM Team",
				"awsGroupName": "Permission:Cor.System_Administrator",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 5,
				"devOnly": true
			}
		}
	},
	"ASSETVIEW": {
		"name": "AssetView",
		"abbreviation": "AV",
		"order": 3,
		"permissions": {
			"VIEWER": {
				"name": "Viewer",
				"abbreviation": "VWR",
				"description": "View access only, can't see unapproved assets, this is the only customer level available.",
				"awsGroupName": "Permission:Av.Viewer",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 1
			},
			"MANAGER": {
				"name": "AssetView Manager",
				"abbreviation": "MGR",
				"description": "Viewer + ability to view unapproved assets, approve them, and make data edits.",
				"awsGroupName": "AssetViewManager",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 2
			},
			"ADMINISTRATOR": {
				"name": "Administrator",
				"abbreviation": "ADM",
				"description": "AssetView Manager + ability to delete data from the system.",
				"awsGroupName": "Permission:Av.Administrator",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 3
			},
			"SYSTEM_ADMINISTRATOR": {
				"name": "System Administrator",
				"abbreviation": "SYSA",
				"description": "Full access - AIM team members",
				"awsGroupName": "Permission:Av.System_Administrator",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 4
			},
			"DEPRECATED_CORRELATOR_DOWNLOADS": {
				"name": "(Deprecated) Correlator Downloads",
				"abbreviation": "CRDL",
				"description": "This is a feature, it should be driven by the above permission groups and removed.  It turns on ability to download Correlator Data from Asset View.",
				"awsGroupName": "Correlator:Downloads",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 5
			}
		}
	},
	"SCANLINE": {
		"name": "ScanLine",
		"abbreviation": "SCAN",
		"order": 5,
		"devOnly": true,
		"permissions": {
			"VIEWER": {
				"name": "Viewer",
				"abbreviation": "VWR",
				"description": "Read / write access to core ScanLine features.",
				"awsGroupName": "Permission:Scan.Viewer",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 1
			},
			"MANAGER": {
				"name": "ScanLine Manager",
				"abbreviation": "MGR",
				"description": "Viewer + ability to approve action plans in their area",
				"awsGroupName": "Permission:Scan.ScanLine_Manager",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 2
			},
			"ADMINISTRATOR": {
				"name": "Administrator",
				"abbreviation": "ADM",
				"description": "Manager + ability to approve and modify any action plan they have access to",
				"awsGroupName": "Permission:Scan.Administrator",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 3
			},
			"SYSTEM_ADMINISTRATOR": {
				"name": "System Administrator",
				"abbreviation": "SYSA",
				"description": "Full access - AIM team members",
				"awsGroupName": "Permission:Scan.System_Administrator",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 4
			}
    },
    "customer_overrides": {
      "Targa": {
        "devOnly": false,
        "permissions": { 
          "READ_ONLY": {
            "name": 'Read Only',
            "abbreviation": 'TRDO',
            "description": 'technician - read access only.',
            "awsGroupName": 'Permission:Scan.Targa.Read_Only',
            "canSelfEdit": false,
            "toggleablePermissionAwsGroupName": [],
            "order": 1
          },
          "PROJECT_MANAGER": {
            "name": 'Project Manager',
            "abbreviation": 'TPM',
            "description": 'Read access only.',
            "awsGroupName": 'Permission:Scan.Targa.Project_Manager',
            "canSelfEdit": false,
            "toggleablePermissionAwsGroupName": [],
            "order": 2
          },
          "TECHNICIAN": {
            "name": 'Technician',
            "abbreviation": 'TTECH',
            "description":
              'create remedial action plans, overlay data',
            "awsGroupName": 'Permission:Scan.Targa.Technician',
            "canSelfEdit": false,
            "toggleablePermissionAwsGroupName": [],
            "order": 3
          },
          "SUPERVISOR": {
            "name": 'Supervisor',
            "abbreviation": 'TSPR',
            "description": 'update, approve remedial action plans',
            "awsGroupName": 'Permission:Scan.Targa.Supervisor',
            "canSelfEdit": false,
            "toggleablePermissionAwsGroupName": [],
            "order": 4
          }
        }
      }
    },
  
	},
	"LIVELINE": {
		"name": "LiveLine",
		"abbreviation": "LL",
		"order": 6,
		"devOnly": true,
		"permissions": {
			"VIEWER": {
				"name": "Viewer",
				"abbreviation": "VWR",
				"description": "Customer view, remove setup screen access.",
				"awsGroupName": "Permission:Ll.Viewer",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 1
			},
			"SYSTEM_ADMINISTRATOR": {
				"name": "System Administrator",
				"abbreviation": "SYSA",
				"description": "Full access - AIM team members",
				"awsGroupName": "Permission:Ll.System_Administrator",
				"canSelfEdit": true,
				"toggleablePermissionAwsGroupName": [],
				"order": 2
			}
		}
	}
}
