/* eslint-disable import/prefer-default-export */
import { selectExpandedRowMapById, selectSelectedSurveysArrMapById, selectSelectedSurveysMapMapById } from '../selectors';
import { 
  collapseAllRows,
  expandRow,
  collapseRow,
  toggleRow,

  clearSelectedSurveys,
  selectSurvey,
  unselectSurvey,
  toggleSelectedSurvey
} from './actions';
import UTIL from './util';

/**
 * EXANDED ROWS
 */

const thunkCollapseAllRows = () => (dispatch, getState) => { 
  const map = selectExpandedRowMapById(getState());
  if (Object.keys(map).length) { 
    dispatch(collapseAllRows());
  }
}

const thunkExpandRow = (item) => (dispatch, getState) => {
  const expandedRowMapById = selectExpandedRowMapById(getState);
  const id = UTIL.selectId(item);
  if (!expandedRowMapById[id]) { 
    dispatch(expandRow(item));
  }
}

const thunkCollapseRow = (item) => (dispatch, getState) => {
  const expandedRowMapById = selectExpandedRowMapById(getState);
  const id = UTIL.selectId(item);
  if (expandedRowMapById[id]) { 
    dispatch(collapseRow(item));
  }
}

const thunkToggleRow = (item) => (dispatch) => {
  dispatch(toggleRow(item));
}

/**
 * SELECTED SURVEYS
 */

const thunkClearSelectedSurveys = () => (dispatch, getState) => {
  const map = selectSelectedSurveysArrMapById(getState());
  if (Object.keys(map).length) { 
    dispatch(clearSelectedSurveys());
  }
}

const thunkSelectSurvey = (lineAgg, survey) => (dispatch, getState) => {
  const map = selectSelectedSurveysMapMapById(getState());
  if (!(map[lineAgg.id] || {})[survey.id]) {
    dispatch(selectSurvey(lineAgg, survey));
  }
}

const thunkUnselectSurvey = (lineAgg, survey) => (dispatch, getState) => {
  const map = selectSelectedSurveysMapMapById(getState());
  if ((map[lineAgg.id] || {})[survey.id]) {
    dispatch(unselectSurvey(lineAgg, survey));
  }
}

const thunkToggleSelectedSurvey = (lineAgg, survey) => (dispatch) => {
  dispatch(toggleSelectedSurvey(lineAgg, survey));
}


export {
  thunkCollapseAllRows,
  thunkExpandRow,
  thunkCollapseRow,
  thunkToggleRow,

  thunkClearSelectedSurveys,
  thunkSelectSurvey,
  thunkUnselectSurvey,
  thunkToggleSelectedSurvey,
};
