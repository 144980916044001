/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { set, setCustomer, setIsLoadingLoaded, clear, clearSelectedFilters, selectFilterItem, unselectFilterItem } from './actions';
import { fetchLinesAggsFilters } from '../../../api/linesaggsfilters';
import { selectCustomer, selectSelectedFiltersMap } from '../selectors';
import UTIL from '../util.selectedFilters';

const thunkLoad = () => (dispatch, getState) => {
  const customerName = selectCustomer(getState());

  dispatch(clear());
  dispatch(setCustomer(customerName));
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
  fetchLinesAggsFilters(customerName).then((filters) => {
		dispatch(set(filters));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

const thunkSetCustomerName = (customerName) => (dispatch) => { 
  dispatch(setCustomer(customerName));
  dispatch(clearSelectedFilters());
  dispatch(thunkLoad());
}

const thunkToggleFilterItem = (filterKey, item) => (dispatch, getState) => { 
  const selectedFiltersMap = selectSelectedFiltersMap(getState());
  const currIsSelected = UTIL.computeIsFilterItemSelected(filterKey, item, selectedFiltersMap)
  if (currIsSelected) {
    dispatch(unselectFilterItem(filterKey, item));
  } else { 
    dispatch(selectFilterItem(filterKey, item));
  }
}



export {
  thunkLoad,
  thunkSetCustomerName,
  thunkToggleFilterItem
};
