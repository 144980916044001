export const initialState = {
  isEditorOpen: false,
  selectedItem: undefined,
  data: undefined,
	isDataLoading: false,
	isDataLoaded: false,
	errorMessage: undefined
};

export default initialState;
