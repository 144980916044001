import React from 'react';
import GeneralizedTooltip from '../Tooltip';

/**
 * under the hood this uses react-bootstrap OverlayTrigger & Tooltip
 * these props are passed in via the title prop, which can be either a string, or an object of OverlayTrigger props.
 * @see https://react-bootstrap-v3.netlify.app/components/overlays/ 
 * @see https://react-bootstrap-v3.netlify.app/components/tooltips/ 
 */

const isEmptyString = v => typeof v === 'string' && v.trim().length === 0;

const computeProps = title => { 
  if (typeof title === 'string' && !isEmptyString(title)) { 
    return { text: title }
  }

  // assume generalized tooltip props object
  return title || {};
}

export default function TitleTooltip({ title, children }) { 
  const { text, ...remainingProps } = computeProps(title);

  if (text) { 
    return (
      <GeneralizedTooltip
        text={text}
        {...remainingProps}
      >
        {children}
      </GeneralizedTooltip>
    )
  }

  return <>{children}</>
}
