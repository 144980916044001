/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { set, setCustomer, setIsLoadingLoaded, clear, clearSelectedFilters, selectFilterItem, unselectFilterItem } from './actions';
import { fetchLinesAggsFilters } from '../../../api/linesaggsfilters';
import { selectCustomer, selectIsLoaded, selectIsLoading, selectSelectedFiltersMap } from '../selectors';
import { thunkCollapseAllRows } from '../../LinkSurveysTableBehaviors/actions';
import UTIL from '../util.selectedFilters';



const thunkLoad = () => (dispatch, getState) => {
  const customerName = selectCustomer(getState());

  dispatch(thunkCollapseAllRows());
  dispatch(clear());
  dispatch(setCustomer(customerName));
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
  fetchLinesAggsFilters(customerName).then((filters) => {
		dispatch(set(filters));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

const thunkLoadIfNotLoaded = () => (dispatch, getState) => {
  const isLoaded = selectIsLoaded(getState());
  const isLoading = selectIsLoading(getState());

  if (!isLoaded && !isLoading) {
    dispatch(thunkLoad());
  }
};

const thunkClearSelectedFilters = () => (dispatch) => { 
  dispatch(thunkCollapseAllRows());
  dispatch(clearSelectedFilters());
}

const thunkSetCustomerName = (customerName) => (dispatch) => { 
  dispatch(setCustomer(customerName));
  dispatch(clearSelectedFilters());
  dispatch(thunkLoad());
}

const thunkToggleFilterItem = (filterKey, item) => (dispatch, getState) => { 
  const selectedFiltersMap = selectSelectedFiltersMap(getState());
  const currIsSelected = UTIL.computeIsFilterItemSelected(filterKey, item, selectedFiltersMap)
  dispatch(thunkCollapseAllRows());
  if (currIsSelected) {
    dispatch(unselectFilterItem(filterKey, item));
  } else { 
    dispatch(selectFilterItem(filterKey, item));
  }
}



export {
  thunkLoad,
  thunkLoadIfNotLoaded,
  thunkClearSelectedFilters,
  thunkSetCustomerName,
  thunkToggleFilterItem
};
