import { selectRootSliceLPR } from '../../Main/selectors';
import { ROOT_SLICE } from './constants';

export const selectThisSlice = state => { 
  const slice = selectRootSliceLPR(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already reports aggs.
  return state;
};

export const selectColumnKeys = state => {
	const slice = selectThisSlice(state);
  const { columnKeys } = slice;
	return columnKeys;
};

export const selectColumnKeysSortList = state => {
	const slice = selectThisSlice(state);
	const { columnKeysSortList } = slice;
	return columnKeysSortList;
};

export const selectIsLoading = state => {
	const slice = selectThisSlice(state);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = state => {
	const slice = selectThisSlice(state);
	const { isLoaded } = slice;
	return isLoaded;
};

export const selectErrorMessage = state => {
	const slice = selectThisSlice(state);
	const { errorMessage } = slice;
	return errorMessage;
};

export const selectIsColumnEnabled = (state, key) => { 
  return !!selectColumnKeys(state)[key];
}

export default {
	selectColumnKeys,
	selectColumnKeysSortList,
	selectIsLoading,
	selectIsLoaded,
  selectErrorMessage,
  selectIsColumnEnabled
};
