export {
  selectThisSlice,
  selectCustomer,
  selectAnalysisPageCustomer,
  selectCustomerSettingsSource,
  selectIsLoading,
  selectIsLoaded,

  selectHasActionPlans,
  selectHasClosureReports,

  selectHasTabLinesCardView,
  selectHasTabLinesTableView,
  selectHasTabMyProjects,

  selectHasTabReports,
  selectHasTabCustomReportsV1,
  selectHasTabCustomReportsV2,
  selectHasTabReportPercentPassing,
  selectHasTabReportTotalMiles,

  selectHasBehaviorAutoRemoveIliData,
  selectHasFeatureClosureCopyActionPlan,
} from './selectors.core';

export {
  selectHasActionPlansForAnalysisPage,
  selectHasClosureReportsForAnalysisPage,
  selectHasBehaviorAutoRemoveIliDataForAnalysisPage,
  selectHasFeatureClosureCopyActionPlanForAnalysisPage,
} from './selectors.analysis.page';