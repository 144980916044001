/* eslint-disable import/no-cycle */
import { initialState } from './initialState';
import {
	setUsers,
	setLoadMeta,
	setHasLoadingErrorUsers,
	setSelectedUser,
	setSelectedUserChangesClone,
	setIsSavingUser,
	setNewUser,
	setIsSavingNewUser,
	setErrorMessageSaveNewUser,
	setIsSavingEditedUser,
	setErrorMessageSaveEditedUser,
	setIsDeletingUser,
	setErrorMessageDeleteUser,
	setShowConfirmation,
	setConfirmationMessageKey,
	reset
} from './actions';
import { ROOT_SLICE } from './constants';
import {
	selectThisSlice,
	selectIsLoadingUsers,
	selectIsLoadedUsers,
	selectHasLoadingErrorUsers,
	selectUsersTotalCount
} from './selectors';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[reset]: state => ({
		...state,
		[ROOT_SLICE]: { ...initialState }
	}),

	[setUsers]: (state, { payload: { users } }) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, users }
		};
	},

	[setSelectedUser]: (state, { payload: { user } }) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedUser: user }
		};
	},

	[setSelectedUserChangesClone]: (state, { payload: { clone } }) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, selectedUserChangesClone: clone }
		};
	},

	[setIsSavingUser]: (state, { payload: { isSaving } }) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, isSavingUser: isSaving }
		};
	},

	[setNewUser]: (state, { payload: { newUser } }) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, newUser }
		};
	},

	[setIsSavingNewUser]: (state, { payload: { isSavingNewUser } }) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, isSavingNewUser }
		};
	},

	[setErrorMessageSaveNewUser]: (
		state,
		{ payload: { errorMessageSaveNewUser } }
	) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, errorMessageSaveNewUser }
		};
	},

	[setIsSavingEditedUser]: (state, { payload: { isSavingEditedUser } }) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, isSavingEditedUser }
		};
	},

	[setErrorMessageSaveEditedUser]: (
		state,
		{ payload: { errorMessageSaveEditedUser } }
	) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, errorMessageSaveEditedUser }
		};
	},

	[setIsDeletingUser]: (state, { payload: { isDeletingUser } }) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, isDeletingUser }
		};
	},

	[setErrorMessageDeleteUser]: (
		state,
		{ payload: { errorMessageDeleteUser } }
	) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, errorMessageDeleteUser }
		};
	},

	[setHasLoadingErrorUsers]: (state, { payload: { hasError } }) => {
		const rootSlice = selectThisSlice(state);
		const currentHasError = selectHasLoadingErrorUsers(state);

		if (hasError === currentHasError) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				hasLoadingErrorUsers: hasError
			}
		};
	},

	[setShowConfirmation]: (state, { payload: { showConfirmation } }) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, showConfirmation }
		};
	},

	[setConfirmationMessageKey]: (
		state,
		{ payload: { confirmationMessageKey } }
	) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, confirmationMessageKey }
		};
	},

	[setLoadMeta]: (
		state,
		{
			payload: {
				isLoadingUsers: newIsLoadingUsers,
				isLoadedUsers: newIsLoadedUsers,
				usersTotalCount: newUsersTotalCount
			}
		}
	) => {
		const rootSlice = selectThisSlice(state);
		const isLoadingUsers = selectIsLoadingUsers(state);
		const isLoadedUsers = selectIsLoadedUsers(state);
		const usersTotalCount = selectUsersTotalCount(state);

		const isLoadingChanged =
			isDefined(newIsLoadingUsers) && newIsLoadingUsers !== isLoadingUsers;
		const isLoadedChanged =
			isDefined(newIsLoadedUsers) && newIsLoadedUsers !== isLoadedUsers;
		const isTotalCountChanged =
			isDefined(newUsersTotalCount) && newUsersTotalCount !== usersTotalCount;

		if (!isLoadingChanged && !isLoadedChanged && !isTotalCountChanged) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoadingUsers: isLoadingChanged ? newIsLoadingUsers : isLoadingUsers,
				isLoadedUsers: isLoadedChanged ? newIsLoadedUsers : isLoadedUsers,
				usersTotalCount: isTotalCountChanged
					? newUsersTotalCount
					: usersTotalCount
			}
		};
	}
};

export default reducerParts;
