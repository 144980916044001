import { getCustomer } from "../../../../../util/user";

export const initialState = {
  customer: getCustomer(),
  filters: {},
  selectedFiltersArr: [],
  selectedFiltersArrByFilterKey: {},
  selectedFiltersMap: {},
  linesAggs: [],
	isLoading: false,
	isLoaded: false,
	errorMessage: undefined
};

export default initialState;
