import AccountUtil from '../../account/components/utils/AccountUtil';

const NS = 'MAIN_';

export const SET_APPLICATIONS = `${NS}SET_APPLICATIONS`;
export const SET_APPLICATIONS_IS_LOADING_IS_LOADED = `${NS}SET_APPLICATIONS_IS_LOADING_IS_LOADED`;

const setApplications = apps => {
	return {
		type: SET_APPLICATIONS,
		payload: apps
	};
};

const setIsLoadingLoaded = (isLoading, isLoaded) => {
	return {
		type: SET_APPLICATIONS_IS_LOADING_IS_LOADED,
    payload: { isLoading, isLoaded }
	};
};

export const getApplications = () => (dispatch, getState) => {
  const state = getState();
  const { isLoading, isLoaded } = state?.main ? state.main : state;
  
  if (!isLoading && !isLoaded) { 
    dispatch(setIsLoadingLoaded(true, false));
    AccountUtil.applications().then(res => {
      dispatch(setApplications(res));
    });
  }
};
