import { COLUMN_KEYS } from '../../../../redux/LinesProjectsReports/slices/LinesAggsColumnSettings/constants';

import ColCustomer from './ColCustomer';
import ColJobNumber from './ColJobNumber';
import ColStartEnd from './ColStartEnd';
import ColEntity from './ColEntity';
import ColArea from './ColArea';
import ColPipeID from './ColPipeID';
import ColRegulatoryStatus from './ColRegulatoryStatus';
import ColSurveyTypes from './ColSurveyTypes';
import ColNeg850mv from './ColNeg850mv';
import ColMiles from './ColMiles';
import ColCTs from './ColCTs';
import ColPMs from './ColPMs';
import ColTags from './ColTags';
import ColLine from './ColLine';

export function getColumnBase(columnKey) {
	switch (columnKey) {
		case COLUMN_KEYS.CUSTOMER:
			return ColCustomer;
		case COLUMN_KEYS.ENTITY:
			return ColEntity;
		case COLUMN_KEYS.AREA:
			return ColArea;
		case COLUMN_KEYS.PIPE:
			return ColPipeID;
		case COLUMN_KEYS.REGULATORY_STATUS:
			return ColRegulatoryStatus;
		case COLUMN_KEYS.JOB_NUMBER:
			return ColJobNumber;
		case COLUMN_KEYS.START_END:
			return ColStartEnd;
		case COLUMN_KEYS.SURVEY_TYPES:
			return ColSurveyTypes;
		case COLUMN_KEYS.NEG_850_MV:
			return ColNeg850mv;
		case COLUMN_KEYS.MILES:
			return ColMiles;
		case COLUMN_KEYS.PMS:
			return ColPMs;
		case COLUMN_KEYS.CTS:
			return ColCTs;
		case COLUMN_KEYS.TAGS:
			return ColTags;
		case COLUMN_KEYS.LINE:
			return ColLine;
		default:
			throw new Error(
				`Failed to match column key to a react component. key: ${columnKey}`
			);
	}
}
