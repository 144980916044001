/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// ADMIN APPLICATIONS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_APPLICATIONS } from './slices/Applications/constants';
import { initialState as AdminApplicationsInitialState } from './slices/Applications/initialState';
import AdminApplicationsReducerParts from './slices/Applications/reducerParts';

// ADMIN COMPANIES
import { ROOT_SLICE as ROOT_SLICE_ADMIN_COMPANIES } from './slices/Companies/constants';
import { initialState as AdminCompaniesInitialState } from './slices/Companies/initialState';
import AdminCompaniesReducerParts from './slices/Companies/reducerParts';

// CUSTOMER ADMIN FEATURES
import { ROOT_SLICE as ROOT_SLICE_CUSTOMER_ADMIN_FEATURES } from './slices/CustomerAdminFeatures/constants';
import { initialState as CustomerAdminFeaturesInitialState } from './slices/CustomerAdminFeatures/initialState';
import CustomerAdminFeaturesReducerParts from './slices/CustomerAdminFeatures/reducerParts';

// ADMIN CUSTOMERS V2
import { ROOT_SLICE as ROOT_SLICE_ADMIN_CUSTOMERS_V2 } from './slices/CustomersV2/constants';
import { initialState as AdminCustomersV2InitialState } from './slices/CustomersV2/initialState';
import AdminCustomersV2ReducerParts from './slices/CustomersV2/reducerParts';

// ADMIN CUSTOMERS SEARCH
import { ROOT_SLICE as ROOT_SLICE_ADMIN_CUSTOMERS_SEARCH } from './slices/CustomersSearch/constants';
import { initialState as AdminCustomersSearchInitialState } from './slices/CustomersSearch/initialState';
import AdminCustomersSearchReducerParts from './slices/CustomersSearch/reducerParts';

// ADMIN ENTITIES EDITOR
import { ROOT_SLICE as ROOT_SLICE_ADMIN_ENTITIES_EDITOR } from './slices/EntitiesEditor/constants';
import { initialState as AdminEntitiesEditorInitialState } from './slices/EntitiesEditor/initialState';
import AdminEntitiesEditorReducerParts from './slices/EntitiesEditor/reducerParts';

// ADMIN AREAS EDITOR
import { ROOT_SLICE as ROOT_SLICE_ADMIN_AREAS_EDITOR } from './slices/AreasEditor/constants';
import { initialState as AdminAreasEditorInitialState } from './slices/AreasEditor/initialState';
import AdminAreasEditorReducerParts from './slices/AreasEditor/reducerParts';

// ADMIN REGULATORY STATUS EDITOR
import { ROOT_SLICE as ROOT_SLICE_ADMIN_REGULATORY_STATUS_EDITOR } from './slices/RegulatoryStatusEditor/constants';
import { initialState as AdminRegulatoryStatusEditorInitialState } from './slices/RegulatoryStatusEditor/initialState';
import AdminRegulatoryStatusEditorReducerParts from './slices/RegulatoryStatusEditor/reducerParts';

// ADMIN AREAS EDITOR
import { ROOT_SLICE as ROOT_SLICE_ADMIN_PIPES_EDITOR } from './slices/PipesEditor/constants';
import { initialState as AdminPipesEditorInitialState } from './slices/PipesEditor/initialState';
import AdminPipesEditorReducerParts from './slices/PipesEditor/reducerParts';

// ADMIN CUSTOMERS - COG CUSTOMERS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_COG_CUSTOMERS } from './slices/Sync/CogCustomers/constants';
import { initialState as AdminCogCustomersInitialState } from './slices/Sync/CogCustomers/initialState';
import AdminCogCustomersReducerParts from './slices/Sync/CogCustomers/reducerParts';

// ADMIN CUSTOMERS - DDB CUSTOMERS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_DDB_CUSTOMERS } from './slices/Sync/DdbCustomers/constants';
import { initialState as AdminDdbCustomersInitialState } from './slices/Sync/DdbCustomers/initialState';
import AdminDdbCustomersReducerParts from './slices/Sync/DdbCustomers/reducerParts';

// ADMIN CUSTOMERS - DDB CUSTOMERS ENTITIES
import { ROOT_SLICE as ROOT_SLICE_ADMIN_DDB_CUSTOMERS_ENTITIES } from './slices/Sync/DdbCustomersEntities/constants';
import { initialState as AdminDdbCustomersEntitiesInitialState } from './slices/Sync/DdbCustomersEntities/initialState';
import AdminDdbCustomersEntitiesReducerParts from './slices/Sync/DdbCustomersEntities/reducerParts';

// ADMIN CUSTOMERS - DDB CUSTOMERS APPLICATIONS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_DDB_CUSTOMERS_SETTINGS } from './slices/Sync/DdbCustomersSettings/constants';
import { initialState as AdminDdbCustomersSettingsInitialState } from './slices/Sync/DdbCustomersSettings/initialState';
import AdminDdbCustomersSettingsReducerParts from './slices/Sync/DdbCustomersSettings/reducerParts';

// ADMIN CUSTOMERS - PSQL CUSTOMERS ENTITIES
import { ROOT_SLICE as ROOT_SLICE_ADMIN_PSQL_CUSTOMERS_ENTITIES } from './slices/Sync/PsqlCustomersEntities/constants';
import { initialState as AdminPsqlCustomersEntitiesInitialState } from './slices/Sync/PsqlCustomersEntities/initialState';
import AdminPsqlCustomersEntitiesReducerParts from './slices/Sync/PsqlCustomersEntities/reducerParts';

// ADMIN CUSTOMERS - PSQL CUSTOMERS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_PSQL_CUSTOMERS } from './slices/Sync/PsqlCustomers/constants';
import { initialState as AdminPsqlCustomersInitialState } from './slices/Sync/PsqlCustomers/initialState';
import AdminPsqlCustomersReducerParts from './slices/Sync/PsqlCustomers/reducerParts';

// ADMIN CUSTOMERS - PSQL CUSTOMERS APPLICATIONS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_PSQL_CUSTOMERS_APPLICATIONS } from './slices/Sync/PsqlCustomersApplications/constants';
import { initialState as AdminPsqlCustomersApplicationsInitialState } from './slices/Sync/PsqlCustomersApplications/initialState';
import AdminPsqlCustomersApplicationsReducerParts from './slices/Sync/PsqlCustomersApplications/reducerParts';

// ADMIN CUSTOMERS - PSQL CUSTOMERS AREAS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_PSQL_CUSTOMERS_AREAS } from './slices/Sync/PsqlCustomerAreas/constants';
import { initialState as AdminPsqlCustomersAreas } from './slices/Sync/PsqlCustomerAreas/initialState';
import AdminPsqlCustomersAreasReducerParts from './slices/Sync/PsqlCustomerAreas/reducerParts';

// ADMIN CUSTOMERS - PSQL CUSTOMERS REGULATORY STATUS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_PSQL_CUSTOMERS_REGULATORY_STATUS } from './slices/Sync/PsqlCustomerRegulatoryStatus/constants';
import { initialState as AdminPsqlCustomersRegulatoryStatus } from './slices/Sync/PsqlCustomerRegulatoryStatus/initialState';
import AdminPsqlCustomersRegulatoryStatusReducerParts from './slices/Sync/PsqlCustomerRegulatoryStatus/reducerParts';

// ADMIN CUSTOMERS - PSQL CUSTOMERS PIPES
import { ROOT_SLICE as ROOT_SLICE_ADMIN_PSQL_CUSTOMERS_PIPES } from './slices/Sync/PsqlCustomerPipes/constants';
import { initialState as AdminPsqlCustomersPipes } from './slices/Sync/PsqlCustomerPipes/initialState';
import AdminPsqlCustomersPipesReducerParts from './slices/Sync/PsqlCustomerPipes/reducerParts';

// ADMIN CUSTOMERS - ASSETVIEW EFS CUSTOMERS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_AV_EFS_CUSTOMERS } from './slices/Sync/AvEfsCustomers/constants';
import { initialState as AdminAvEfsCustomersInitialState } from './slices/Sync/AvEfsCustomers/initialState';
import AdminAvEfsCustomersReducerParts from './slices/Sync/AvEfsCustomers/reducerParts';

// ADMIN USERS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_USERS } from './slices/Users/constants';
import { initialState as AdminUsersInitialState } from './slices/Users/initialState';
import AdminUsersReducerParts from './slices/Users/reducerParts';

// ADMIN USERS - PSQL USERS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_PSQL_USERS } from './slices/Sync/PsqlUsers/constants';
import { initialState as AdminPsqlUsersInitialState } from './slices/Sync/PsqlUsers/initialState';
import AdminPsqlUsersReducerParts from './slices/Sync/PsqlUsers/reducerParts';

// ADMIN USERS SEARCH
import { ROOT_SLICE as ROOT_SLICE_ADMIN_USERS_SEARCH } from './slices/UsersSearch/constants';
import { initialState as AdminUsersSearchInitialState } from './slices/UsersSearch/initialState';
import AdminUsersSearchReducerParts from './slices/UsersSearch/reducerParts';

// ADMIN RESTRICTIONS DEFINITIONS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_RESTRICTION_DEFINITIONS } from './slices/RestrictionDefinitions/constants';
import { initialState as AdminRestrictionDefinitionsInitialState } from './slices/RestrictionDefinitions/initialState';
import AdminRestrictionDefinitionsReducerParts from './slices/RestrictionDefinitions/reducerParts';

// ADMIN PERMISSION DEFINITIONS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_PERMISSION_DEFINITIONS } from './slices/PermissionDefinitions/constants';
import { initialState as AdminPermissionDefinitionsInitialState } from './slices/PermissionDefinitions/initialState';
import AdminPermissionDefinitionsReducerParts from './slices/PermissionDefinitions/reducerParts';

// ADMIN SAVE ERRORS
import { ROOT_SLICE as ROOT_SLICE_ADMIN_SAVE_ERRORS } from './slices/SaveErrors/constants';
import { initialState as AdminSaveErrorsInitialState } from './slices/SaveErrors/initialState';
import AdminSaveErrorsReducerParts from './slices/SaveErrors/reducerParts';

const initialState = {
	[ROOT_SLICE_ADMIN_APPLICATIONS]: {
		...AdminApplicationsInitialState
	},
	[ROOT_SLICE_ADMIN_COMPANIES]: {
		...AdminCompaniesInitialState
	},
	[ROOT_SLICE_CUSTOMER_ADMIN_FEATURES]: {
		...CustomerAdminFeaturesInitialState
	},
	[ROOT_SLICE_ADMIN_CUSTOMERS_V2]: {
		...AdminCustomersV2InitialState
	},
	[ROOT_SLICE_ADMIN_CUSTOMERS_SEARCH]: {
		...AdminCustomersSearchInitialState
	},
	[ROOT_SLICE_ADMIN_ENTITIES_EDITOR]: {
		...AdminEntitiesEditorInitialState
	},
	[ROOT_SLICE_ADMIN_AREAS_EDITOR]: {
		...AdminAreasEditorInitialState
	},
	[ROOT_SLICE_ADMIN_REGULATORY_STATUS_EDITOR]: {
		...AdminRegulatoryStatusEditorInitialState
	},
	[ROOT_SLICE_ADMIN_PIPES_EDITOR]: {
		...AdminPipesEditorInitialState
	},
	[ROOT_SLICE_ADMIN_COG_CUSTOMERS]: {
		...AdminCogCustomersInitialState
	},
	[ROOT_SLICE_ADMIN_DDB_CUSTOMERS]: {
		...AdminDdbCustomersInitialState
	},
	[ROOT_SLICE_ADMIN_DDB_CUSTOMERS_ENTITIES]: {
		...AdminDdbCustomersEntitiesInitialState
	},
	[ROOT_SLICE_ADMIN_DDB_CUSTOMERS_SETTINGS]: {
		...AdminDdbCustomersSettingsInitialState
	},
	[ROOT_SLICE_ADMIN_PSQL_CUSTOMERS]: {
		...AdminPsqlCustomersInitialState
	},
	[ROOT_SLICE_ADMIN_PSQL_CUSTOMERS_ENTITIES]: {
		...AdminPsqlCustomersEntitiesInitialState
	},
	[ROOT_SLICE_ADMIN_PSQL_CUSTOMERS_APPLICATIONS]: {
		...AdminPsqlCustomersApplicationsInitialState
	},
	[ROOT_SLICE_ADMIN_PSQL_CUSTOMERS_AREAS]: {
		...AdminPsqlCustomersAreas
	},
	[ROOT_SLICE_ADMIN_PSQL_CUSTOMERS_REGULATORY_STATUS]: {
		...AdminPsqlCustomersRegulatoryStatus
	},
	[ROOT_SLICE_ADMIN_PSQL_CUSTOMERS_PIPES]: {
		...AdminPsqlCustomersPipes
	},
	[ROOT_SLICE_ADMIN_AV_EFS_CUSTOMERS]: {
		...AdminAvEfsCustomersInitialState
	},
	[ROOT_SLICE_ADMIN_USERS]: {
		...AdminUsersInitialState
	},
	[ROOT_SLICE_ADMIN_PSQL_USERS]: { ...AdminPsqlUsersInitialState },
	[ROOT_SLICE_ADMIN_USERS_SEARCH]: {
		...AdminUsersSearchInitialState
	},
	[ROOT_SLICE_ADMIN_RESTRICTION_DEFINITIONS]: {
		...AdminRestrictionDefinitionsInitialState
	},
	[ROOT_SLICE_ADMIN_PERMISSION_DEFINITIONS]: {
		...AdminPermissionDefinitionsInitialState
	},
	[ROOT_SLICE_ADMIN_SAVE_ERRORS]: {
		...AdminSaveErrorsInitialState
	}
};

const reducerParts = {
	...AdminApplicationsReducerParts,
	...AdminCompaniesReducerParts,
	...CustomerAdminFeaturesReducerParts,
	...AdminCustomersV2ReducerParts,
	...AdminCustomersSearchReducerParts,
	...AdminEntitiesEditorReducerParts,
	...AdminAreasEditorReducerParts,
	...AdminRegulatoryStatusEditorReducerParts,
	...AdminPipesEditorReducerParts,
	...AdminCogCustomersReducerParts,
	...AdminDdbCustomersReducerParts,
	...AdminDdbCustomersEntitiesReducerParts,
	...AdminDdbCustomersSettingsReducerParts,
	...AdminPsqlCustomersReducerParts,
	...AdminPsqlCustomersEntitiesReducerParts,
	...AdminPsqlCustomersApplicationsReducerParts,
	...AdminPsqlCustomersAreasReducerParts,
	...AdminPsqlCustomersRegulatoryStatusReducerParts,
	...AdminPsqlCustomersPipesReducerParts,
	...AdminAvEfsCustomersReducerParts,
	...AdminUsersReducerParts,
	...AdminPsqlUsersReducerParts,
	...AdminUsersSearchReducerParts,
	...AdminPermissionDefinitionsReducerParts,
	...AdminRestrictionDefinitionsReducerParts,
	...AdminSaveErrorsReducerParts
};

export default handleActions(reducerParts, initialState);
