/* eslint-disable import/prefer-default-export */
import { createSelector } from "reselect";

import { DateTimeless } from "aegion_common_utilities/lib/ReadingsUtil";
import { selectSelectedFiltersMap } from "../../LinkSurveysFilters/selectors";
import { selectItems as selectLinesAggs } from "../../LinesAggs/selectors";

import { FILTER_KEYS } from "../../LinkSurveysFilters/util.selectedFilters";
import { selectDateRangeFrom, selectDateRangeTo, selectDateRangeType, selectIsDateRangeEnabled } from "../../LinkSurveysFilterDateRange/selectors";

const { 
  CUSTOMERS,
  ENTITIES,
  AREAS,
  PIPES,
  REGULATORY_STATUSES,
  JOBS,
  SURVEY_TYPES,
  PMS,
  CTS
} = FILTER_KEYS

/**
 * HELPERS
 */

const createSelectedMapCounts = (selectedMap) => Object.keys(selectedMap).reduce((acc, key) => {
  acc[key] = Object.keys(selectedMap[key] || {}).length;
  return acc;
}, {});

const isDefined = v => v !== undefined && v !== null;

export const isInRange = (lineAgg, dateRangeProps) => { 
  const {
    enabled,
    from,
    to
  } = dateRangeProps;

  if (!enabled || (!from && !to)) { 
    return true;
  }

  if (!isDefined(lineAgg.sortEpoch)) { 
    return false;
  }

  const ts = new Date(new DateTimeless(lineAgg.sortEpoch)).getTime();
  const fromTS = isDefined(from) ? new Date(new DateTimeless(from)).getTime() : undefined;
  const toTS = isDefined(to) ? new Date(new DateTimeless(to)).getTime() : undefined;

  if (isDefined(fromTS) && isDefined(toTS)) {
    return fromTS <= ts && ts <= toTS;
  }

  if (isDefined(fromTS)) {
    return fromTS <= ts;
  }

  if (isDefined(toTS)) {
    return ts <= toTS;
  }

  return false;
}

/**
 * SURVEYS
 */

const surveyHasSurveyType = (survey, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[SURVEY_TYPES] ?
    true : 
    !!(selectedMap[SURVEY_TYPES] || {})[survey.typeId];
}

const surveysHas = (surveys = [], selectedMap, selectedMapCounts) => { 
  return surveys.some(s => {
    return surveyHasSurveyType(s, selectedMap, selectedMapCounts);
  });
}

/**
 * JOB FILTERS
 */

const jobHasEntity = (job, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[ENTITIES] ?
    true : 
    !!(selectedMap[ENTITIES] || {})[job.entityId];
}

const jobHasArea = (job, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[AREAS] ?
    true : 
    !!(selectedMap[AREAS] || {})[job.areaId];
}

const jobHasPipe = (job, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[PIPES] ?
    true : 
    !!(selectedMap[PIPES] || {})[job.pipeId];
}

const jobHasRegulatoryStatus = (job, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[REGULATORY_STATUSES] ?
    true : 
    !!(selectedMap[REGULATORY_STATUSES] || {})[job.regulatoryStatusId];
}

const jobIsJob = (job, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[JOBS] ?
    true : 
    !!(selectedMap[JOBS] || {})[job.id];
}

const jobHasPM = (job, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[PMS] ?
    true : 
    !!(selectedMap[PMS] || {})[job.pmId];
}

const jobHasCT = (job, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[CTS] ?
    true : 
    !!(selectedMap[CTS] || {})[job.ctId];
}

const jobsHas = (jobs = [], selectedMap, selectedMapCounts) => { 
  return jobs.some(j => {
    return jobHasEntity(j, selectedMap, selectedMapCounts) &&
      jobHasArea(j, selectedMap, selectedMapCounts) &&
      jobHasPipe(j, selectedMap, selectedMapCounts) &&
      jobHasRegulatoryStatus(j, selectedMap, selectedMapCounts) &&
      jobIsJob(j, selectedMap, selectedMapCounts) &&
      jobHasPM(j, selectedMap, selectedMapCounts) &&
      jobHasCT(j, selectedMap, selectedMapCounts) &&
      surveysHas(j.surveys, selectedMap, selectedMapCounts);
  });
}

/**
 * LINE AGG FILTERS - TOP LEVEL
 */

const lineAggHasCustomer = (lineAgg, selectedMap, selectedMapCounts) => {
  return !selectedMapCounts[CUSTOMERS] ?
    true : 
    !!(selectedMap[CUSTOMERS] || {})[lineAgg.customerId];
}

const lineAggHas = (lineAgg, selectedMap, selectedMapCounts, dateRangeProps) => { 
  return (
    lineAggHasCustomer(lineAgg, selectedMap, selectedMapCounts) &&
    isInRange(lineAgg, dateRangeProps) &&
    jobsHas(lineAgg.jobs, selectedMap, selectedMapCounts)
  );
};

// should only be used within /LinkSurveysFiltersUI/selectore/
const _INTERNAL_UTIL = {
  createSelectedMapCounts,
  lineAggHas,
  lineAggHasCustomer,
  jobHasEntity,
  jobHasArea,
  jobsHas,
  jobIsJob,
  jobHasPipe,
  jobHasRegulatoryStatus,
  jobHasPM,
  jobHasCT,
  surveysHas,
  surveyHasSurveyType
}

const selectFilteredLinesAggs = createSelector(
  selectLinesAggs,
  selectSelectedFiltersMap,
  selectIsDateRangeEnabled,
  selectDateRangeType,
  selectDateRangeFrom,
  selectDateRangeTo,  
  (
    linesAggs,
    selectedMap,
    dateRangeEnabled,
    dateRangeType,
    dateRangeFrom,
    dateRangeTo
  ) => {
    const selectedMapCounts = createSelectedMapCounts(selectedMap);
    const dateRangeProps = {
      type: dateRangeType,
      enabled: dateRangeEnabled,
      from: dateRangeFrom,
      to: dateRangeTo
    }
    return linesAggs.filter(la => lineAggHas(la, selectedMap, selectedMapCounts, dateRangeProps));
  });

export { 
  _INTERNAL_UTIL,
  selectFilteredLinesAggs
}

export default {
  _INTERNAL_UTIL,
  selectFilteredLinesAggs
}
