import { selectRootSliceLPR } from '../../Main/selectors';
import { ROOT_SLICE } from './constants';

export const selectThisSlice = state => { 
  const slice = selectRootSliceLPR(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already reports aggs.
  return state;
};

export const selectFilterKeys = state => {
	const slice = selectThisSlice(state);
  const { filterKeys } = slice;
	return filterKeys;
};

export const selectFilterKeysSortList = state => {
	const slice = selectThisSlice(state);
	const { filterKeysSortList } = slice;
	return filterKeysSortList;
};

export const selectIsLoading = state => {
	const slice = selectThisSlice(state);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = state => {
	const slice = selectThisSlice(state);
	const { isLoaded } = slice;
	return isLoaded;
};

export const selectErrorMessage = state => {
	const slice = selectThisSlice(state);
	const { errorMessage } = slice;
	return errorMessage;
};

export const selectIsFilterEnabled = (state, key) => { 
  return !!selectFilterKeys(state)[key];
}

export default {
	selectFilterKeys,
	selectFilterKeysSortList,
	selectIsLoading,
	selectIsLoaded,
  selectErrorMessage,
  selectIsFilterEnabled
};
