/* eslint-disable import/no-named-as-default-member */
import { selectRootSliceLPR } from '../../Main/selectors';
import { ROOT_SLICE } from './constants';
import { FILTER_KEYS } from './util.selectedFilters';

const {
  CUSTOMERS,
  ENTITIES,
  AREAS,
  PIPES,
  REGULATORY_STATUSES,
  JOBS,
  SURVEY_TYPES,
  PMS,
  CTS,
  REPORT_TYPES,
  REPORT_STATUSES,
  PROJECT_TYPES,
  REMEDIATION_TYPES
} = FILTER_KEYS;

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];


const protectEmptyArray = (arr) => {
  if (arr && arr.length === 0) {
    return EMPTY_ARRAY;
  }

  return arr || EMPTY_ARRAY;
}

const protectEmptyObject = (obj) => { 
  if (obj && Object.keys(obj).length === 0) { 
    return EMPTY_OBJECT;
  }

  return obj || EMPTY_OBJECT;
}

export const selectThisSlice = state => { 
  const slice = selectRootSliceLPR(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already reports aggs.
  return state;
};

export const selectCustomer = (state) => { 
	const slice = selectThisSlice(state);
	const { customer } = slice;
	return customer;
}

export const selectFilters = state => {
  const slice = selectThisSlice(state);
  const { filters } = slice;
	return filters;
};

export const selectFilter = (state, filterKey) => {
  const customer = selectCustomer(state);
  const filters = selectFilters(state) || {};
  return (filters[customer] || {})[filterKey];
};

export const hasFilter = (state, filterKey) => {
  return !!selectFilter(state, filterKey);
};

export const selectIsLoading = state => {
	const slice = selectThisSlice(state);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = state => {
	const slice = selectThisSlice(state);
	const { isLoaded } = slice;
	return isLoaded;
};

export const selectErrorMessage = state => {
	const slice = selectThisSlice(state);
	const { errorMessage } = slice;
	return errorMessage;
};

export const selectSelectedFiltersArr = state => {
	const slice = selectThisSlice(state);
	const { selectedFiltersArr } = slice;
	return selectedFiltersArr;
};

export const selectSelectedFiltersArrByFilterKey = (state) => { 
	const slice = selectThisSlice(state);
	const { selectedFiltersArrByFilterKey } = slice;
	return selectedFiltersArrByFilterKey;
}

export const selectSelectedFiltersMap = state => {
	const slice = selectThisSlice(state);
	const { selectedFiltersMap } = slice;
	return protectEmptyObject(selectedFiltersMap);
};

export const selectSelectedFiltersList = (state, filterKey) => {
  const map = selectSelectedFiltersArrByFilterKey(state);
  return protectEmptyArray(map[filterKey]);
}


/** ******************
 * SPECIFIC FILTERS
 */

export const selectFilterItems = (state, filterKey) => { 
  const customer = selectCustomer(state);
  const filters = selectFilters(state);

  if ([CUSTOMERS, SURVEY_TYPES, REPORT_TYPES, REPORT_STATUSES, PROJECT_TYPES].indexOf(filterKey) > -1 ) {
    return protectEmptyArray((filters || {})[filterKey]);
  }

  return protectEmptyArray(((filters || {})[filterKey] || {})[customer]);
}

export const selectCustomersFilterItems = (state) => {
  return selectFilterItems(state, CUSTOMERS);
}

export const selectSelectedCustomersFiltersList = (state) => { 
  return selectSelectedFiltersList(state, CUSTOMERS)
}

export const selectEntitiesFilterItems = (state) => {
  return selectFilterItems(state, ENTITIES);
}

export const selectSelectedEntitiesFiltersList = (state) => { 
  return selectSelectedFiltersList(state, ENTITIES)
}

export const selectAreasFilterItems = (state) => {
  return selectFilterItems(state, AREAS);
}

export const selectSelectedAreasFiltersList = (state) => { 
  return selectSelectedFiltersList(state, AREAS)
}

export const selectPipesFilterItems = (state) => {
  return selectFilterItems(state, PIPES);
}

export const selectSelectedPipesFiltersList = (state) => { 
  return selectSelectedFiltersList(state, PIPES)
}

export const selectRegulatoryStatusFilterItems = (state) => {
  return selectFilterItems(state, REGULATORY_STATUSES);
}

export const selectSelectedRegulatoryStatusFiltersList = (state) => { 
  return selectSelectedFiltersList(state, REGULATORY_STATUSES)
}

export const selectJobFilterItems = (state) => {
  return selectFilterItems(state, JOBS);
}

export const selectSelectedJobFiltersList = (state) => { 
  return selectSelectedFiltersList(state, JOBS)
}

export const selectSurveyTypesFilterItems = (state) => {
  return selectFilterItems(state, SURVEY_TYPES);
}

export const selectSelectedSurveyTypesFiltersList = (state) => { 
  return selectSelectedFiltersList(state, SURVEY_TYPES)
}

export const selectPmsFilterItems = (state) => {
  return selectFilterItems(state, PMS);
}

export const selectSelectedPmsFiltersList = (state) => { 
  return selectSelectedFiltersList(state, PMS)
}

export const selectCtsFilterItems = (state) => {
  return selectFilterItems(state, CTS);
}

export const selectSelectedCtsFiltersList = (state) => { 
  return selectSelectedFiltersList(state, CTS)
}

export const selectReportTypesFilterItems = (state) => {
  return selectFilterItems(state, REPORT_TYPES);
}

export const selectSelectedReportTypesFiltersList = (state) => { 
  return selectSelectedFiltersList(state, REPORT_TYPES)
}

export const selectReportStatusesFilterItems = (state) => {
  return selectFilterItems(state, REPORT_STATUSES);
}

export const selectSelectedReportStatusesFiltersList = (state) => { 
  return selectSelectedFiltersList(state, REPORT_STATUSES)
}

export const selectProjectTypesFilterItems = (state) => {
  return selectFilterItems(state, PROJECT_TYPES);
}

export const selectSelectedProjectTypesFiltersList = (state) => { 
  return selectSelectedFiltersList(state, PROJECT_TYPES)
}

export const selectRemediationTypesFilterItems = (state) => {
  return selectFilterItems(state, REMEDIATION_TYPES);
}

export const selectSelectedRemediationTypesFiltersList = (state) => { 
  return selectSelectedFiltersList(state, REMEDIATION_TYPES)
}

export default {
	selectFilters,
	selectIsLoading,
	selectIsLoaded,
  selectErrorMessage,
  
  selectFilterItems,
};
