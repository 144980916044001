/* eslint-disable import/prefer-default-export */
export const injectAegionFilters = (filters) => { 
  let results = filters;
  const assumeIsAegionRequest = filters?.customers?.length > 0; // customers should not be returned unless the request was made by an aegion user.

  if (assumeIsAegionRequest) { 
    results = { ...filters };
    Object.keys(filters).forEach(filterGroup => { 
      if (filterGroup !== 'customers') { 
        const companyKeys = Object.keys(filters[filterGroup]);
        const aegionItems = companyKeys.map(comp => filters[filterGroup][comp]).flat();
        results[filterGroup].Aegion = aegionItems;
      }
    })
  }

  return results;
}