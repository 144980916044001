/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { 
  setSurveys,
  setReadings,
  setFlatReadings, 
  setSelectedReading, 
  setSelectedSurvey, 
  setMapLines, 
  setStartReadingsEndReadings, 
  setFocusedChart, 
  setChartsBcSeriesDs,
  clear
} from './actions';
import { ROOT_SLICE } from './constants';
import { initialState } from './initialState';
import {
	selectThisSlice
} from './selectors';

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
			[ROOT_SLICE]: _.cloneDeep(initialState)
		};
	},

	[setSurveys]: (state, { payload: { surveys } }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        surveys
			}
		};
  },

	[setReadings]: (state, { payload: {readingsWithGeoGaps, readingsWithGeoGapsIndexMap} }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        readingsWithGeoGaps,
        readingsWithGeoGapsIndexMap
			}
		};
  },

	[setFlatReadings]: (state, { payload: {flattenedReadings, flattenedReadingsIndexMap} }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        flattenedReadings,
        flattenedReadingsIndexMap
			}
		};
  },

	[setSelectedReading]: (state, { payload: {selectedReading, selectedReadingSurvey} }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        selectedReading,
        selectedReadingSurvey
			}
		};
  },

	[setSelectedSurvey]: (state, { payload: {selectedReadingSurvey} }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        selectedReading: undefined,
        selectedReadingSurvey
			}
		};
  },

	[setMapLines]: (state, { payload: {mapLines} }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        mapLines
			}
		};
  },

	[setStartReadingsEndReadings]: (state, { payload: {startReadings, endReadings} }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        startReadings,
        endReadings
			}
		};
  },

	[setFocusedChart]: (state, { payload: {focusedChart} }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        focusedChart
			}
		};
  },

	[setChartsBcSeriesDs]: (state, { payload: {chartsBcSeriesDs} }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        chartsBcSeriesDs
			}
		};
  },
  
};

export default reducerParts;
