import _ from 'lodash';
import { createSortNumeric } from 'aegion_common_utilities/lib/Sort';

const sortByOrder = createSortNumeric('ASC', { getValue: (o = {}) => o.order });

export const transformCloudDefinitionsToClientDefinitions = (
	paramRestrictionDefinitions = {},
	entitiesData = [],
	areasData = []
) => {
	const restrictionDefinitions = _.cloneDeep(paramRestrictionDefinitions);
	const appKeys = Object.keys(restrictionDefinitions);
	appKeys.forEach(appKey => {
		const app = restrictionDefinitions[appKey];
		app.key = appKey;

		const restrictionKeys = Object.keys(app.restrictions || {});
		restrictionKeys.forEach(restrictionKey => {
			const restriction = app.restrictions[restrictionKey];
			restriction.key = restrictionKey;
			restriction.appKey = appKey;
		});
		if (app.customer_overrides) {
			const customerKeys = Object.keys(app.customer_overrides);
			customerKeys.forEach(customerKey => {
				const _restrictions =
					app.customer_overrides[customerKey]?.restrictions || {};
				const _restrictionKeys = Object.keys(_restrictions);
				_restrictionKeys.forEach(restrictionKey => {
					const restriction = _restrictions[restrictionKey];
					restriction.key = restrictionKey;
					restriction.appKey = appKey;
					restriction.customerKey = customerKey;
				});
			});
		}
	}, {});

	function getRestrictions(entitiyName) {
		return areasData
			.filter(({ entityName: name }) => name === entitiyName)
			.reduce((acc, area, idx) => {
				const { name } = area;
				acc[name] = {
					abbreviation: name,
					awsGroupName: name,
					canSelfEdit: false,
					description: name,
					name,
					order: idx,
					toggleablePermissionAwsGroupName: []
				};
				return acc;
			}, {});
	}

	const entitiesDefinitions = entitiesData.reduce((acc, name, idx) => {
		const key = name.replace(/\s/g, '_');
		acc[key] = {
			key,
			name,
			abbreviation: name,
			order: idx,
			restrictions: getRestrictions(name)
		};
		return acc;
	}, {});
	return entitiesDefinitions;
};

export const createMapRestrictionsByGroupName = (
	restrictionDefinitions = {}
) => {
	const map = {};
	const appKeys = Object.keys(restrictionDefinitions);
	appKeys.forEach(appKey => {
		const app = restrictionDefinitions[appKey];
		const restrictionKeys = Object.keys(app.restrictions || {});
		restrictionKeys.forEach(restrictionKey => {
			const restriction = app.restrictions[restrictionKey];
			map[restriction.awsGroupName] = restriction;
		});
		if (app.customer_overrides) {
			const customerKeys = Object.keys(app.customer_overrides);
			customerKeys.forEach(customerKey => {
				const _restrictions =
					app.customer_overrides[customerKey]?.restrictions || {};
				const _restrictionKeys = Object.keys(_restrictions);
				_restrictionKeys.forEach(restrictionKey => {
					const restriction = _restrictions[restrictionKey];
					map[restriction.awsGroupName] = restriction;
				});
			});
		}
	});
	return map;
};

export const createListRestrictionDefinitions = (
	restrictionDefinitions = {}
) => {
	const appKeys = Object.keys(restrictionDefinitions);
	const list = appKeys.map(k => restrictionDefinitions[k]);
	list.sort(sortByOrder);
	return list;
};

export const createMapRestrictionsListByAppKey = (
	restrictionDefinitions = {}
) => {
	const map = {};
	const appKeys = Object.keys(restrictionDefinitions);
	appKeys.forEach(appKey => {
		const app = restrictionDefinitions[appKey];
		const restrictionKeys = Object.keys(app.restrictions || {});
		const list = restrictionKeys.map(
			restrictionKey => app.restrictions[restrictionKey]
		);
		if (app.customer_overrides) {
			const customerKeys = Object.keys(app.customer_overrides);
			customerKeys.forEach(customerKey => {
				const _restrictions =
					app.customer_overrides[customerKey]?.restrictions || {};
				const _restrictionKeys = Object.keys(_restrictions);
				_restrictionKeys.forEach(restrictionKey => {
					const restriction = _restrictions[restrictionKey];
					list.push(restriction);
				});
			});
		}
		list.sort(sortByOrder);
		map[app.key] = list;
	});
	return map;
};

export default {
	transformCloudDefinitionsToClientDefinitions,
	createMapRestrictionsByGroupName,
	createListRestrictionDefinitions,
	createMapRestrictionsListByAppKey
};
