/* eslint-disable import/no-cycle */
import { set, setCustomer, setIsLoadingLoaded, setErrorMessage, clear } from './actions';
import { thunkLoad, thunkLoadIfNotLoaded, thunkSetCustomerName } from './thunks';

export {
  set,
  setCustomer,
  setIsLoadingLoaded,
  setErrorMessage,
  clear,
  
  thunkLoad,
  thunkLoadIfNotLoaded,
  thunkSetCustomerName
};
