import { selectRootSliceAdmin } from '../../../Main/selectors';
import { ROOT_SLICE } from './constants';


export const selectThisSlice = state => { 
  const slice = selectRootSliceAdmin(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already lines aggs.
  return state;
};

export const selectBoolMap = state => {
	const slice = selectThisSlice(state);
	const { boolMap } = slice;
	return boolMap;
};

export const selectIsLoading = state => {
	const slice = selectThisSlice(state);
	const { isLoading } = slice;
	return isLoading;
};

export const selectIsLoaded = state => {
	const slice = selectThisSlice(state);
	const { isLoaded } = slice;
	return isLoaded;
};

export const selectErrorMessage = state => {
	const slice = selectThisSlice(state);
	const { errorMessage } = slice;
	return errorMessage;
};

export const selectPsqlGroupnames = (state) => { 
	const slice = selectThisSlice(state);
	const { psqlGroupnames } = slice;
	return psqlGroupnames;
}

export default {
  selectBoolMap,
  selectPsqlGroupnames,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage
};
