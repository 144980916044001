/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
	setCustomer,
	setRestrictionDefinitions,
	setIsLoadingLoadedRestrictionDefinitions,
	setHasLoadingErrorRestrictionDefinitions
} = createActions(
	{
		SET_CUSTOMER: customer => ({ customer }),
		SET_RESTRICTION_DEFINITIONS: ({
			restrictionDefinitions,
			mapRestrictionsByGroupName,
			listRestrictionDefinitions,
			mapRestrictionsListByAppKey
		}) => ({
			restrictionDefinitions,
			mapRestrictionsByGroupName,
			listRestrictionDefinitions,
			mapRestrictionsListByAppKey
		}),
		SET_IS_LOADING_LOADED_RESTRICTION_DEFINITIONS: ({
			isLoadingRestrictionDefinitions,
			isLoadedRestrictionDefinitions
		}) => ({ isLoadingRestrictionDefinitions, isLoadedRestrictionDefinitions }),
		SET_HAS_LOADING_ERROR_RESTRICTION_DEFINITIONS: hasError => ({ hasError })
	},
	{ prefix: ROOT_SLICE }
);

export {
	setCustomer,
	setRestrictionDefinitions,
	setIsLoadingLoadedRestrictionDefinitions,
	setHasLoadingErrorRestrictionDefinitions
};
