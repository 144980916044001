import { getCustomer } from "../../../../../util/user";

const sessionCustomer = getCustomer();

/**
 * ROOT_SLICE
 */
const ROOT_SLICE = 'ReportsAggsColumnSettings';

/**
 * COLUMN_KEYS
 */

const COLUMN_KEYS = {
  LINE: 'LINE',
  CUSTOMER: 'CUSTOMER',
  ENTITY: 'ENTITY',
  AREA: 'AREA',
  PIPE: 'PIPE',
  REGULATORY_STATUS: 'REGULATORY_STATUS',
  JOB_NUMBER: 'JOB_NUMBER',
  START_END: 'START_END',
  SURVEY_TYPES: 'SURVEY_TYPES',
  PMS: 'PMS',
  CTS: 'CTS',
  TAGS: 'TAGS',
  REPORT_TYPE: 'REPORT_TYPE',
  REPORT_STATUS: 'REPORT_STATUS',
  REPORT_DATE: 'REPORT_DATE',
  COMPLIANCE_DATE: 'COMPLIANCE_DATE',
  REMEDIATION_TYPES: 'REMEDIATION_TYPES',
  REMEDIATION_TYPE_COUNTS: 'REMEDIATION_TYPE_COUNTS',
  PROJECT_TYPE: 'PROJECT_TYPE'
};

const AEGION_ONLY_COLUMN_KEYS = {
  [COLUMN_KEYS.CUSTOMER]: COLUMN_KEYS.CUSTOMER
};

const REQUIRED_COLUMN_KEYS = {
  [COLUMN_KEYS.LINE]: COLUMN_KEYS.LINE
};

const DEFAULT_COLUMN_KEYS = {
  [COLUMN_KEYS.LINE]: COLUMN_KEYS.LINE,
  [COLUMN_KEYS.CUSTOMER]: COLUMN_KEYS.CUSTOMER,
  [COLUMN_KEYS.JOB_NUMBER]: COLUMN_KEYS.JOB_NUMBER,
  [COLUMN_KEYS.START_END]: COLUMN_KEYS.START_END,
  [COLUMN_KEYS.SURVEY_TYPES]: COLUMN_KEYS.SURVEY_TYPES,
  [COLUMN_KEYS.PMS]: COLUMN_KEYS.PMS,
  [COLUMN_KEYS.CTS]: COLUMN_KEYS.CTS,
  [COLUMN_KEYS.REPORT_TYPE]: COLUMN_KEYS.REPORT_TYPE,
  [COLUMN_KEYS.REPORT_STATUS]: COLUMN_KEYS.REPORT_STATUS,
  [COLUMN_KEYS.COMPLIANCE_DATE]: COLUMN_KEYS.COMPLIANCE_DATE,
  [COLUMN_KEYS.REMEDIATION_TYPES]: COLUMN_KEYS.REMEDIATION_TYPES,
  [COLUMN_KEYS.PROJECT_TYPE]: COLUMN_KEYS.PROJECT_TYPE,
}

if (sessionCustomer !== 'Aegion') { 
  // remove AEGION_ONLY_COLUMN_KEYS
  Object.keys(AEGION_ONLY_COLUMN_KEYS).forEach(key => {
    delete DEFAULT_COLUMN_KEYS[key];
  });
}

// Add Missing REQUIRED_COLUMN_KEYS
Object.keys(REQUIRED_COLUMN_KEYS).forEach(key => {
  if (!DEFAULT_COLUMN_KEYS[key]) {
    DEFAULT_COLUMN_KEYS[key] = key;
  }
});

// Targa - custom DEFAULT_COLUMN_KEYS
if (sessionCustomer === 'Targa') { 
  DEFAULT_COLUMN_KEYS[COLUMN_KEYS.ENTITY] = COLUMN_KEYS.ENTITY;
  DEFAULT_COLUMN_KEYS[COLUMN_KEYS.AREA] = COLUMN_KEYS.AREA;
  DEFAULT_COLUMN_KEYS[COLUMN_KEYS.PIPE] = COLUMN_KEYS.PIPE;
  DEFAULT_COLUMN_KEYS[COLUMN_KEYS.REGULATORY_STATUS] = COLUMN_KEYS.REGULATORY_STATUS;
}

/**
 * COLUMN_KEYS_DEFAULT_SORT
 */

const DEFAULT_COLUMN_KEYS_SORT_LIST = [
  COLUMN_KEYS.LINE,
  COLUMN_KEYS.PROJECT_TYPE,
  COLUMN_KEYS.REPORT_TYPE,
  COLUMN_KEYS.REPORT_STATUS,
  COLUMN_KEYS.REPORT_DATE,
  COLUMN_KEYS.COMPLIANCE_DATE,
  COLUMN_KEYS.REMEDIATION_TYPES,
  COLUMN_KEYS.REMEDIATION_TYPE_COUNTS,
  COLUMN_KEYS.CUSTOMER,
  COLUMN_KEYS.ENTITY,
  COLUMN_KEYS.AREA,
  COLUMN_KEYS.PIPE,
  COLUMN_KEYS.REGULATORY_STATUS,
  COLUMN_KEYS.JOB_NUMBER,
  COLUMN_KEYS.START_END,
  COLUMN_KEYS.SURVEY_TYPES,
  COLUMN_KEYS.PMS,
  COLUMN_KEYS.CTS,
  COLUMN_KEYS.TAGS
].filter(key => (sessionCustomer === 'Aegion' || !AEGION_ONLY_COLUMN_KEYS[key]));

export { 
  ROOT_SLICE,

  COLUMN_KEYS,
  AEGION_ONLY_COLUMN_KEYS,
  REQUIRED_COLUMN_KEYS,
  DEFAULT_COLUMN_KEYS,

  DEFAULT_COLUMN_KEYS_SORT_LIST
}


export default { 
  ROOT_SLICE,

  COLUMN_KEYS,
  AEGION_ONLY_COLUMN_KEYS,
  REQUIRED_COLUMN_KEYS,
  DEFAULT_COLUMN_KEYS,

  DEFAULT_COLUMN_KEYS_SORT_LIST
}
