export const initialState = {
  surveys: undefined,
  //  checkedSurveys: undefined, // should be computed with memoized selector -> (surveys || []).filter(s => s.checked) 
  readingsWithGeoGaps: undefined,
  readingsWithGeoGapsIndexMap: undefined,
  flattenedReadings: undefined,
  flattenedReadingsIndexMap: undefined,
  selectedReading: undefined,
  selectedReadingSurvey: undefined,
  mapLines: undefined,
  startReadings: undefined,
  endReadings: undefined,
  focusedChart: undefined,
  chartsBcSeriesDs: undefined,
  heloo: 'hello'
};

export default initialState;
