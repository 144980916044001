/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';

// CORE
import { ROOT_SLICE as ROOT_SLICE_CORE } from './slices/Core/constants';
import { initialState as Core } from './slices/Core/initialState';
import CoreReducerParts from './slices/Core/reducerParts';

// DEBUG TAB MANAGERS - DTM
import { ROOT_SLICE as ROOT_SLICE_DTM } from './slices/DebugTabManagers/constants';
import { initialState as DebugTabManagers } from './slices/DebugTabManagers/initialState';
import DebugTabManagersReducerParts from './slices/DebugTabManagers/reducerParts';


const initialState = {
  [ROOT_SLICE_CORE]: {
    ...Core
  },
  [ROOT_SLICE_DTM]: {
    ...DebugTabManagers
  }  
};

const reducerParts = {
  ...CoreReducerParts,
  ...DebugTabManagersReducerParts,
};

export { ROOT_SLICE } from './constants'
export default handleActions(reducerParts, initialState);
