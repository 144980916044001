/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { selectRootSliceAdmin } from '../../Main/selectors';
import { ROOT_SLICE } from './constants';

export const selectThisSlice = state => {
	const slice = selectRootSliceAdmin(state);
	if (slice[ROOT_SLICE]) {
		return slice[ROOT_SLICE];
	}

	// else assume state is already lines aggs.
	return slice;
};

export const selectCustomer = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { customer } = slice;
	return customer;
};

export const selectRestrictionDefinitions = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { restrictionDefinitions } = slice;
	return restrictionDefinitions;
};

export const selectMapRestrictionsByGroupName = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { mapRestrictionsByGroupName } = slice;
	return mapRestrictionsByGroupName;
};

export const selectRestrictionByGroupName = (stateAdmin, groupName) => {
	const map = selectMapRestrictionsByGroupName(stateAdmin);
	return map[groupName];
};

export const selectListRestrictionDefinitions = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { listRestrictionDefinitions } = slice;
	return listRestrictionDefinitions;
};

export const selectMapRestrictionsListByAppKey = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { mapRestrictionsListByAppKey } = slice;
	return mapRestrictionsListByAppKey;
};

export const selectListRestrictions = (stateAdmin, appKey) => {
	return selectMapRestrictionsListByAppKey(stateAdmin)[appKey];
};

export const selectIsLoadingRestrictionDefinitions = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { isLoadingRestrictionDefinitions } = slice;
	return isLoadingRestrictionDefinitions;
};

export const selectIsLoadedRestrictionDefinitions = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { isLoadedRestrictionDefinitions } = slice;
	return isLoadedRestrictionDefinitions;
};

export const selectHasLoadingErrorRestrictionDefinitions = stateAdmin => {
	const slice = selectThisSlice(stateAdmin);
	const { hasLoadingErrorRestrictionDefinitions } = slice;
	return hasLoadingErrorRestrictionDefinitions;
};

export const selectListRestrictionDefinitionsForCustomer = createSelector(
	selectCustomer,
	selectListRestrictionDefinitions,
	selectRestrictionDefinitions,
	(customer, list, definitions) => {
		if (!customer) {
			return list;
		}

		const hasCustomerOverrides = appKey =>
			(definitions[appKey]?.customer_overrides || {})[customer];

		return list.map(app => {
			return !hasCustomerOverrides(app.key)
				? app
				: {
						...app,
						restrictions: app.customer_overrides[customer]
				  };
		});
		//.filter(app => Object.keys(app.restrictions).length > 0);
	}
);

export const selectListRestrictionsMapForCustomer = createSelector(
	selectCustomer,
	selectMapRestrictionsListByAppKey,
	selectRestrictionDefinitions,
	(customer, listRestrictionsMap, definitions) => {
		if (!customer) {
			return listRestrictionsMap;
		}

		const hasCustomerOverrides = appKey =>
			(definitions[appKey]?.customer_overrides || {})[customer];

		const appKeys = Object.keys(listRestrictionsMap);

		return appKeys.reduce((acc, appKey) => {
			acc[appKey] = !hasCustomerOverrides(appKey)
				? listRestrictionsMap[appKey].filter(perm => !perm.customerKey)
				: listRestrictionsMap[appKey].filter(
						perm => perm.customerKey === customer
				  );
			return acc;
		}, {});
	}
);

export const selectListEnabledRestrictionDefinitionsByGroupName = (
	state,
	mapEnabledGroupNames
) => {
	const resultList = [];
	const enabledAppKeys = {};
	const isEnabledGroupNamesEmpty =
		Object.keys(mapEnabledGroupNames).length === 0;

	if (isEnabledGroupNamesEmpty) {
		return resultList;
	}

	// identify enabled apps
	const appList = selectListRestrictionDefinitions(state);
	appList.forEach(app => {
		const { key } = app;
		const plist = selectListRestrictions(state, key);
		if (plist.some(p => mapEnabledGroupNames[p.awsGroupName])) {
			enabledAppKeys[key] = true;
		}
	});

	// build unique restriction defintions consisting only of enabled apps and restrictions.
	appList.forEach(app => {
		if (enabledAppKeys[app.key]) {
			const clone = { ...app };
			const pList = selectListRestrictions(state, clone.key);
			clone.restrictions = pList.filter(
				({ awsGroupName }) => mapEnabledGroupNames[awsGroupName]
			);
			resultList.push(clone);
		}
	});

	return resultList;
};
