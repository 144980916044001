/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
  clear,

  collapseAllRows,
  expandAllRows,
  expandRow,
  collapseRow,
  toggleRow,

  clearSelectedSurveys,
  selectSurvey,
  unselectSurvey,
  toggleSelectedSurvey
  
} = createActions(
	{
    CLEAR: () => ({}),

    EXPAND_ALL_ROWS: () => ({}),
    COLLAPSE_ALL_ROWS: () => ({}),
    EXPAND_ROW: (item) => ({ item }), 
    COLLAPSE_ROW: (item) => ({ item }), 
    TOGGLE_ROW: (item) => ({ item }), 

    CLEAR_SELECTED_SURVEYS: () => ({}),
    SELECT_SURVEY: (lineAgg, survey) => ({ lineAgg, survey }),
    UNSELECT_SURVEY: (lineAgg, survey) => ({ lineAgg, survey }),
    TOGGLE_SELECTED_SURVEY: (lineAgg, survey) => ({ lineAgg, survey }),

	},
	{ prefix: ROOT_SLICE }
);

const {
  setIsLinking,
  setLinkErrorMessage,
  clearLinkErrorMessage,
} = createActions(
  {
    SET_IS_LINKING: (isLinking) => ({ isLinking }),
    SET_LINK_ERROR_MESSAGE: (linkErrorMessage) => ({ linkErrorMessage }),
    CLEAR_LINK_ERROR_MESSAGE: () => ({})
	},
	{ prefix: ROOT_SLICE }
);

export {
  clear,
  expandAllRows,
  collapseAllRows,
  expandRow,
  collapseRow,
  toggleRow,

  clearSelectedSurveys,
  selectSurvey,
  unselectSurvey,
  toggleSelectedSurvey,

  setIsLinking,
  setLinkErrorMessage,
  clearLinkErrorMessage,
};
