import {
  setEnabled,
  setType,
  setFromAndTo,
  clear
} from './actions';

import { thunkSetFromAndTo } from './thunks.core';

import {
	thunkToggleDateRange,
	thunkSetDateRangeTypeFromList,
	thunkFilterDateRange
} from './thunks.ui';

// import { thunkLoadDateRangeStateFromUrl } from './thunks.private.urlparams';

export {
	// PURE ACTION CREATORS
	setEnabled,
	setType,
  setFromAndTo,
  clear,

	// THUNKS
	thunkSetFromAndTo,
	thunkToggleDateRange,
	thunkSetDateRangeTypeFromList,
	thunkFilterDateRange

	// thunkLoadDateRangeStateFromUrl,
};

export default {
	// PURE ACTION CREATORS
	setEnabled,
	setType,
  setFromAndTo,
  clear,

	// THUNKS
	thunkSetFromAndTo,
	thunkToggleDateRange,
	thunkSetDateRangeTypeFromList,
	thunkFilterDateRange

	// thunkLoadDateRangeStateFromUrl,
};
