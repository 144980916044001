import React, { useMemo } from 'react';

const Horizontal = ({ strList, delimiter }) => { 
  const formattedValue = useMemo(() => (strList || '').split(delimiter).join(', '), [strList, delimiter]);

  return <div>{formattedValue}</div>
}

const Vertical = ({ strList, delimiter }) => { 
  const values = useMemo(() => (strList || '').split(delimiter), [strList, delimiter]);

  return <div>{(values || []).map(v => <div key={`string-list-key-${v}`}>{v}</div>)}</div>
}

const HelperRenderStringList = ({ strList, delimiter = '~', direction = 'horizontal' } = {}) => { 
  switch (direction) { 
    case 'horizontal':
      return <Horizontal strList={strList} delimiter={delimiter} />;
    case 'vertical':
      return <Vertical strList={strList} delimiter={delimiter} />;
    default:
      return <Horizontal strList={strList} delimiter={delimiter} />;
  }
}

export default HelperRenderStringList;