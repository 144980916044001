import { selectRootSliceLPR } from '../../Main/selectors';
import { ROOT_SLICE } from './constants';

export const selectThisSlice = state => { 
  const slice = selectRootSliceLPR(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already LAFDateRange.
  return state;
};

export const selectDateRangeType = stateAV => {
	const slice = selectThisSlice(stateAV);
	return slice.type;
};

export const selectDateRangeFrom = stateAV => {
	const slice = selectThisSlice(stateAV);
	return slice.from;
};

export const selectDateRangeTo = stateAV => {
	const slice = selectThisSlice(stateAV);
	return slice.to;
};

export const selectIsDateRangeEnabled = stateAV => {
	const slice = selectThisSlice(stateAV);
	return !!slice.enabled;
};
