/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
  setFromAndTo,
  setType,
  setEnabled,
  clear
} = createActions(
	{
    SET_FROM_AND_TO: ({ from, to }) => ({ from, to }),
    SET_TYPE: (type) => ({ type }),
    SET_ENABLED: (enabled) => ({ enabled }),
    CLEAR: () => ({})
	},
	{ prefix: ROOT_SLICE }
);

export { setType, setFromAndTo, setEnabled, clear };

export default { setType, setFromAndTo, setEnabled, clear };
