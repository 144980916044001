import { selectRootMainMapPopoutState } from '../../Main/selectors';
import { ROOT_SLICE } from './constants';

export const selectThisSlice = state => { 
  const slice = selectRootMainMapPopoutState(state);
  if (slice[ROOT_SLICE]) { 
    return slice[ROOT_SLICE];
  }

  // else assume state is already Core.
  return state;
};

export const selectReadingsMeta = state => {
	const slice = selectThisSlice(state);
  const { readingsWithGeoGaps, readingsWithGeoGapsIndexMap } = slice;
  return {
    readings: readingsWithGeoGaps,
    readingsIndexMap: readingsWithGeoGapsIndexMap,
  };
};

// checkedSurveys: (core.surveys || []).filter(s => s.checked),


export const selectFlatReadingsMeta = state => {
	const slice = selectThisSlice(state);
  const { flattenedReadings, flattenedReadingsIndexMap } = slice;
  return {
    readings: flattenedReadings,
    readingsIndexMap: flattenedReadingsIndexMap,
  };
};

export const selectSelectedReading = (state) => {
	const slice = selectThisSlice(state);
  const { selectedReading } = slice;
  return selectedReading;
}

export const selectSelectedReadingSurvey = (state) => {
	const slice = selectThisSlice(state);
  const { selectedReadingSurvey } = slice;
  return selectedReadingSurvey;
}

export const selectMapLines = (state) => {
	const slice = selectThisSlice(state);
  const { mapLines } = slice;
  return mapLines;
}

export const selectStartReadings = (state) => {
	const slice = selectThisSlice(state);
  const { startReadings } = slice;
  return startReadings;
}

export const selectEndReadings = (state) => {
	const slice = selectThisSlice(state);
  const { endReadings } = slice;
  return endReadings;
}

export const selectFocusedChart = (state) => {
	const slice = selectThisSlice(state);
  const { focusedChart } = slice;
  return focusedChart;
}

export const selectChartsBcSeriesDs = (state) => {
	const slice = selectThisSlice(state);
  const { chartsBcSeriesDs } = slice;
  return chartsBcSeriesDs;
}

export default {
  selectReadingsMeta,
  selectFlatReadingsMeta,
  selectSelectedReading,
  selectSelectedReadingSurvey,
  selectMapLines,
  selectStartReadings,
  selectEndReadings,
  selectFocusedChart,
  selectChartsBcSeriesDs
};
