import React, { useMemo } from 'react';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const DEFAULT_HEADER = 'Start / End';
const PROP_KEY = 'txtStartEnd';

/** ****************************
 * MAIN
 */

const TH = ({ header = DEFAULT_HEADER, className }) => (
	<div className={className}>{header}</div>
);

const TD = ({ item = EMPTY_OBJECT, className }) => {
	const value = useMemo(() => (item || {})[PROP_KEY] || '', [item]);

	return <td className={className}>{value}</td>;
};

const SortFunction = item => {
	return new Date(item.start);
};

const ColStartEnd = { TH, TD, CSV_PROP: PROP_KEY, SortFunction };

export default ColStartEnd;
