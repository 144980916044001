/* eslint-disable import/no-cycle */
import {
	set,
	setCustomer,
	setIsLoadingLoaded,
	setErrorMessage,
	clear
} from './actions';
import {
	thunkSet,
	thunkLoad,
	thunkLoadIfNotLoaded,
	thunkSetCustomerName
} from './thunks';

export {
	set,
	setCustomer,
	setIsLoadingLoaded,
	setErrorMessage,
	clear,
	thunkSet,
	thunkLoad,
	thunkLoadIfNotLoaded,
	thunkSetCustomerName
};
