/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const {
  setSettings,
  setIsLoadingLoaded,
  setErrorMessage,
  clear,
  resetToDefaults,

  enableColumn,
  disableColumn,
  toggleColumn,

  moveColumnSort
} = createActions(
	{
    SET_SETTINGS: (columnKeys, columnKeysSortList) => ({ columnKeys, columnKeysSortList }),
		SET_IS_LOADING_LOADED: ({ isLoading, isLoaded }) => ({
			isLoading,
			isLoaded
		}),
		SET_ERROR_MESSAGE: errorMessage => ({ errorMessage }),
		CLEAR: () => ({}),
    
    RESET_TO_DEFAULTS: () => ({}),

    ENABLE_COLUMN: (key) => ({ key }),
    DISABLE_COLUMN: (key) => ({ key }),
    TOGGLE_COLUMN: (key) => ({ key }),

    MOVE_COLUMN_SORT: (key, toIdx) => ({key, toIdx})
	},
	{ prefix: ROOT_SLICE }
);

export {
  setSettings,
  setIsLoadingLoaded,
  setErrorMessage,
  clear,
  resetToDefaults,

  enableColumn,
  disableColumn,
  toggleColumn,

  moveColumnSort
};
