import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { computeClassname, computeTooltipTitleObject } from './util.and.constants';

import TitleTooltip from '../TitleTooltip';

import './AimButtons.scss';
import './AimButtons.material.scss';

const AimButton = ({
	children,
  title,
  disabled,
	className: propClassName,
	iconClassname, // remove from remainingProps
	bsStyle = 'default',
	...remainingprops
}) => {
	const className = useMemo(() => computeClassname(propClassName), [
		propClassName
	]);
  const _title = useMemo(() => computeTooltipTitleObject(title, disabled), [title, disabled])
  return (
    <TitleTooltip title={_title}>
      <Button bsStyle={bsStyle} className={className} disabled={disabled} {...remainingprops}>
			  {children}
      </Button>
    </TitleTooltip>
	);
};

export default AimButton;
