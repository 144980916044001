import { getCustomer } from "../../../../../util/user";
import { FILTER_KEYS } from "../PercentPassingFilters/util.selectedFilters";

const sessionCustomer = getCustomer();

/**
 * ROOT_SLICE
 */
const ROOT_SLICE = 'PercentPassingFilterSettings';

/**
 * FILTER_KEYS
 */

// this is needed because FILTER_KEYS.PROP will often not map to "PROP", but rather to "props" or something else which is not an exact match to "PROP".
const INVERTED_FILTER_KEYS_MAP = Object.keys(FILTER_KEYS).reduce((acc, key) => {
  acc[FILTER_KEYS[key]] = key;
  return acc;
}, {});

const AEGION_ONLY_FILTER_KEYS = {
  [FILTER_KEYS.CUSTOMERS]: FILTER_KEYS.CUSTOMERS
};

const REQUIRED_FILTER_KEYS = {};

const DEFAULT_FILTER_KEYS = {
  [FILTER_KEYS.CUSTOMERS]: FILTER_KEYS.CUSTOMERS,
  [FILTER_KEYS.ENTITIES]: FILTER_KEYS.ENTITIES,
  [FILTER_KEYS.SURVEY_TYPES]: FILTER_KEYS.SURVEY_TYPES,
}

if (sessionCustomer !== 'Aegion') { 
  // remove AEGION_ONLY_FILTER_KEYS
  Object.keys(AEGION_ONLY_FILTER_KEYS).forEach(key => {
    delete DEFAULT_FILTER_KEYS[key];
  });
}

// Add Missing REQUIRED_FILTER_KEYS
Object.keys(REQUIRED_FILTER_KEYS).forEach(key => {
  if (!DEFAULT_FILTER_KEYS[key]) {
    DEFAULT_FILTER_KEYS[key] = key;
  }
});

// Targa - custom DEFAULT_FILTER_KEYS
if (sessionCustomer === 'Targa') { 
  DEFAULT_FILTER_KEYS[FILTER_KEYS.AREAS] = FILTER_KEYS.AREAS;
}

/**
 * FILTER_KEYS_DEFAULT_SORT
 */

const DEFAULT_FILTER_KEYS_SORT_LIST = [
  FILTER_KEYS.CUSTOMERS,
  FILTER_KEYS.ENTITIES,
  FILTER_KEYS.AREAS,
  FILTER_KEYS.PIPES,
  FILTER_KEYS.REGULATORY_STATUSES,
  FILTER_KEYS.JOBS,
  FILTER_KEYS.SURVEY_TYPES,
  FILTER_KEYS.PMS,
  FILTER_KEYS.CTS,
].filter(key => (sessionCustomer === 'Aegion' || !AEGION_ONLY_FILTER_KEYS[key]));

export { 
  ROOT_SLICE,

  FILTER_KEYS,
  INVERTED_FILTER_KEYS_MAP,
  AEGION_ONLY_FILTER_KEYS,
  REQUIRED_FILTER_KEYS,
  DEFAULT_FILTER_KEYS,

  DEFAULT_FILTER_KEYS_SORT_LIST
}


export default { 
  ROOT_SLICE,

  FILTER_KEYS,
  INVERTED_FILTER_KEYS_MAP,
  AEGION_ONLY_FILTER_KEYS,
  REQUIRED_FILTER_KEYS,
  DEFAULT_FILTER_KEYS,

  DEFAULT_FILTER_KEYS_SORT_LIST
}
