/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../../utils/token';
import { createTableItemAndKey, sortBySortEpoch } from './util/reportsaggs';

export const fetchReportsAggs = async (customerName) => {
	const url = '/reports';

	if (hasAccessToken()) {
		const fetchData = createPromiseRequest({
      url,
      method: 'POST',
      data: {
        customer: customerName
      }      
		});

    return fetchData()
      .then(res => res.map(i => createTableItemAndKey(i)).sort(sortBySortEpoch));
      // .then(res => { 
      //   console.log(JSON.stringify(res));
      //   return res;
      // })
	}
	return Promise.reject(new Error('Missing Token'));
};

