/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../../utils/token';
import { injectAegionFilters } from './util/lineaggsfilters';

export const fetchLinesAggsFilters = async (customerName) => {
	const url = '/filters';

	if (hasAccessToken()) {
		const fetchData = createPromiseRequest({
      url,
      method: 'POST',
      data: {
        customer: customerName
      }      
		});

		return fetchData().then(res => injectAegionFilters(res));
	}
	return Promise.reject(new Error('Missing Token'));
};

