/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../../utils/token';

export const postLinkSurveys = async (customer, lineExternalId, surveyIds = []) => {
	const url = '/link-surveys';

  if (hasAccessToken()) {
		const postData = createPromiseRequest({
      url,
      method: 'POST',
      aip: 'scanline',
      data: {
        customer,
        lineExternalId, 
        surveyIds
      }
		});

		return postData();
	}
	return Promise.reject(new Error('Missing Token'));
};

