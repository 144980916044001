import React, { useMemo } from 'react';
import HelperRenderStringList from './HelperRenderStringList';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const DEFAULT_HEADER = 'Technicians';
const PROP_KEY = 'cts';



/** ****************************
 * MAIN
 */

const TH = ({ header = DEFAULT_HEADER }) => <>{header}</>;

const TD = ({ item = EMPTY_OBJECT }) => {
  const value = useMemo(() => (item || {})[PROP_KEY] || '', [item]);

  return <td><HelperRenderStringList strList={value} direction='vertical' /></td>;
};

const ColCTs = { TH, TD, CSV_PROP: PROP_KEY };

export default ColCTs;
