const customer = {
	name: 'Targa',

	emails: {
		pods: '',
		corrosion: ''
	}
};

export default customer;
