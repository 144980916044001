/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../../utils/token';

export const fetchCustomerSettingsSource = async () => {
	const url = '/doc/customers/settings';

	if (hasAccessToken()) {
		const fetchData = createPromiseRequest({
      url,
      method: 'GET',
      aip: 'admin'
		});

		return fetchData();
	}
	return Promise.reject(new Error('Missing Token'));
};

