import { selectThisSlice } from './selectors';
import { setSorting } from './actions';
import { ROOT_SLICE } from './constants';

const reducerParts = {
	[setSorting]: (
		state,
		{ payload: { sortColumn, sortDirection } = {} } = {}
	) => {
		const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				sortColumn,
				sortDirection
			}
		};
	}
};

export default reducerParts;
