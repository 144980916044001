/* eslint-disable no-use-before-define */

const APP_STATE_ACTION_PLAN_VERSION_RECOMPUTE = 1.1;

const helperSelectAppStateComparator = (key, actionPlanVersion) => {
	const validComparators = {
		GREATER_THAN: 'GREATER_THAN',
		GREATER_THAN_EQUAL_TO: 'GREATER_THAN_EQUAL_TO'
	};

	const result = validComparators[key];

	return (
		result ||
		(actionPlanVersion < APP_STATE_ACTION_PLAN_VERSION_RECOMPUTE
			? 'GREATER_THAN_EQUAL_TO'
			: 'GREATER_THAN')
	);
};

export const selectStateApp = (state) => { 
  if (state?.app) { 
    return state.app;
  }

  // else assume state is already at slice "app".
  return state;
};


// APP STATE META
export const selectAppStateData = (state = {}) => {
	const { appState: { meta = {} } = {} } = state;

	return meta;
};

// APP STATE ACTION PLAN VERSION
export const selectAppStateComparator = (state = {}) => {
	const {
		appState: {
			meta: { actionPlanComparator = '', actionPlanVersion = 0 } = {}
		} = {}
	} = state;

	return helperSelectAppStateComparator(
		actionPlanComparator,
		actionPlanVersion
	);
};

// APP STATE THRESHOLD
export const selectAppStateThreshold = (state = {}) => {
	const { appState: { meta: { threshold = -850 } = {} } = {} } = state;

	return threshold;
};

// APP STATE USE SHADOW
export const selectAppStateUseShadow = (state = {}) => {
	const { appState: { meta: { useShadow = true } = {} } = {} } = state;

	return useShadow;
};

// APP STATE REMEDIATION ACTIONS
export const selectAppStateRemediationActions = (state = {}) => {
	const { appState: { meta: { remediationActions = {} } = {} } = {} } = state;

	return remediationActions && Object.keys(remediationActions).length > 0
		? remediationActions
		: undefined;
};

// APP STATE CUSTOM GROUPS
export const selectAppStateCustomGroups = (state = {}) => {
	const { appState: { meta: { customGroups = [] } = {} } = {} } = state;

	return customGroups && customGroups.length > 0 ? customGroups : undefined;
};

// APP STATE USE INTERPOLATED DEPOL
export const selectAppStateUseInterpolatedDepol = (state = {}) => {
	const {
		appState: { meta: { useInterpolatedDepol = false } = {} } = {}
	} = state;

	return useInterpolatedDepol;
};

// APP STATE USE NORMALIZED DEPOL
export const selectAppStateUseNormalizedDepol = (state = {}) => {
	const {
		appState: { meta: { useNormalizedDepol = undefined } = {} } = {}
	} = state;

	return useNormalizedDepol;
};

// APP STATE REPORT DATA
export const selectAppStateReportData = (state = {}) => {
	const { appState = {} } = state;
	const { meta, ...remainingProps } = appState;

	return remainingProps || {};
};

// IS APP STATE LOADED
export const selectIsAppStateLoaded = (state = {}) => {
	const { isAppStateLoaded } = state;

	return isAppStateLoaded;
};

// APP STATE ACTION PLAN VERSION
export const selectActionReportVersion = (state = {}) => {
	const { actionReportVersion = 0 } = state;

	return actionReportVersion;
};

// APP STATE ACTION PLAN VERSION
export const selectActionReportComparator = (state = {}) => {
	const { actionReportComparator = '', actionReportVersion = 0 } = state;

	return helperSelectAppStateComparator(
		actionReportComparator,
		actionReportVersion
	);
};

// ACTION REPORT THRESHOLD
export const selectActionReportThreshold = (state = {}) => {
	const { actionReportThreshold = -850 } = state;

	return actionReportThreshold;
};

// ACTION REPORT USE SHADOW
export const selectActionReportUseShadow = (state = {}) => {
	const { actionReportUseShadow = true } = state;

	return actionReportUseShadow;
};

// ACTION REPORT CUSTOM GROUPS
export const selectActionReportCustomGroups = (state = {}) => {
	const { actionReportCustomGroups } = state;

	return actionReportCustomGroups;
};

// INTERPOLATED DEPOL FOR DEFAULT SURVEY
export const selectUseInterpolatedDepolForDefaultSurvey = (state = {}) => {
	const { useInterpolatedDepol, defaultSurvey } = state;

	return defaultSurvey ? useInterpolatedDepol[defaultSurvey.id] : false;
};

// INTERPOLATED DEPOL FOR SURVEY
export const selectUseInterpolatedDepolForSurvey = (survey, appState = {}) => {
	const { useInterpolatedDepol } = appState;

	return survey ? useInterpolatedDepol[survey.id] : false;
};

// NORMALIZED DEPOL FOR DEFAULT SURVEY
export const selectUseNormalizedDepolForDefaultSurvey = (state = {}) => {
	const { defaultSurvey, useNormalizedDepol } = state;
	return getUseNormalizedDepolFromMap(defaultSurvey, useNormalizedDepol);
};

// NORMALIZED DEPOL FOR SURVEY
export const selectUseNormalizedDepolForSurvey = (survey, appState = {}) => {
	const { useNormalizedDepol } = appState;
	return getUseNormalizedDepolFromMap(survey, useNormalizedDepol);
};

const getUseNormalizedDepolFromMap = (survey, useNormalizedDepolMap = {}) => {
	if (!survey) {
		return false;
	}

	let result = useNormalizedDepolMap[survey.id];
	result = result === undefined ? !survey.disable_auto_align : result;
	return result;
};
