/* eslint-disable import/prefer-default-export */
import users from './users';
import companies from './companies';
import permissionDefinitions2 from './permissionDefinitions2';
import helloWorld from './helloworld';

const selectDataSet = url => {
	const [path] = (url || '').split('?');
	switch (path) {
		case '/users':
			return users;
		case '/companies':
			return companies;
		case '/permission-definitions':
			return permissionDefinitions2;
		case '/hello-world':
			return helloWorld;
		default:
			return [];
	}
};

export const fauxAjax = (url, data, method, callback) => {
	const dataSet = selectDataSet(url);
	setTimeout(() => {
		callback(undefined, dataSet);
	}, 1);
};
