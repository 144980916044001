/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
import { ROOT_SLICE, DEFAULT_FILTER_KEYS, DEFAULT_FILTER_KEYS_SORT_LIST } from './constants';
import { stateChangesForReset } from './initialState';
import {
  setSettings,
  setIsLoadingLoaded,
  setErrorMessage,
  clear,
  resetToDefaults,

  enableFilter,
  disableFilter,
  toggleFilter,

  moveFilterSort
} from './actions';
import {
	selectThisSlice,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage,
  selectFilterKeys,
  selectFilterKeysSortList
} from './selectors';
import UTIL from './util';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
      [ROOT_SLICE]: {
        ...state[ROOT_SLICE],
        ...stateChangesForReset
      }
		};
  },
  
  [resetToDefaults]: (state) => { 
    const rootSlice = selectThisSlice(state);
    return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        filterKeys: { ...DEFAULT_FILTER_KEYS },
        filterKeysSortList: [...DEFAULT_FILTER_KEYS_SORT_LIST]
			}
    };
  },

  [setSettings]: (state, { payload: { filterKeys, filterKeysSortList } }) => {
    const rootSlice = selectThisSlice(state);
    const result = {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,

        filterKeys: filterKeys ?
          UTIL.replaceDefaultFilterKeys(filterKeys) :
          { ...DEFAULT_FILTER_KEYS },

        filterKeysSortList: filterKeysSortList ?
          UTIL.replaceDefaultFilterKeySortList(filterKeysSortList) :
          [...DEFAULT_FILTER_KEYS_SORT_LIST]
			}
    };

    return result;
  },

	[setIsLoadingLoaded]: (
		state,
		{ payload: { isLoading: newIsLoading, isLoaded: newIsLoaded } }
	) => {
		const rootSlice = selectThisSlice(state);
		const isLoading = selectIsLoading(state);
		const isLoaded = selectIsLoaded(state);

		const CHANGED = {
			isLoading: isDefined(newIsLoading) && newIsLoading !== isLoading,
			isLoaded: isDefined(newIsLoaded) && newIsLoaded !== isLoaded
		};

		if (!CHANGED.isLoaded && !CHANGED.isLoading) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoading: CHANGED.isLoading ? newIsLoading : isLoading,
				isLoaded: CHANGED.isLoaded ? newIsLoaded : isLoaded
			}
		};
	},

	[setErrorMessage]: (
		state,
		{ payload: { errorMessage: newErrorMessage } }
	) => {
		const rootSlice = selectThisSlice(state);
		const errorMessage = selectErrorMessage(state);

		if (newErrorMessage === errorMessage) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				errorMessage: newErrorMessage
			}
		};
  },

  [enableFilter]: (
		state,
		{ payload: { key } }
	) => {
    const rootSlice = selectThisSlice(state);
    const filterKeys = selectFilterKeys(state);

		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        filterKeys: UTIL.enableFilter(filterKeys, key)
			}
		};
  },

  [disableFilter]: (
		state,
		{ payload: { key } }
	) => {
		const rootSlice = selectThisSlice(state);
    const filterKeys = selectFilterKeys(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        filterKeys: UTIL.disableFilter(filterKeys, key)
			}
		};
  },

  [toggleFilter]: (
		state,
		{ payload: { key } }
	) => {
		const rootSlice = selectThisSlice(state);
    const filterKeys = selectFilterKeys(state);

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        filterKeys: UTIL.toggleFilter(filterKeys, key)
			}
		};
  },

  [moveFilterSort]: (
		state,
		{ payload: { key, toIdx } }
	) => {
    const rootSlice = selectThisSlice(state);
    const filterKeysSortList = selectFilterKeysSortList(state);

		return {
			...state,
			[ROOT_SLICE]: {
        ...rootSlice,
        filterKeysSortList: UTIL.moveFilterSort(filterKeysSortList, key, toIdx)
			}
		};
  },
};

export default reducerParts;
