/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { ROOT_SLICE } from '../constants';

const { set, clear } = createActions(
	{
    SET: ({
      namespace,
      tabId,
      thisTab,
      tabs,
      otherTabs,
      tabsMap
    }) => ({
      namespace,
      tabId,
      thisTab,
      tabs,
      otherTabs,
      tabsMap
    }),
		CLEAR: () => ({})
	},
	{ prefix: ROOT_SLICE }
);

export { set, clear };
