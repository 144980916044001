import { YYYY_MM_DD } from 'aegion_common_utilities/lib/format/datetime';

import { setFromAndTo, setType } from './actions';

import {
	getDateRangeTypeMeta,
	sanitizeDateRangeTypeKey
} from '../util/DateRangeTypes';
import {
	selectDateRangeType,
	selectDateRangeTo,
	selectDateRangeFrom,
	selectThisSlice
} from '../selectors';

const thunkSetFromAndTo = (payload = {}) => dispatch => {
	const { from: paramFrom, to: paramTo } = payload;
	const from = paramFrom instanceof Date ? YYYY_MM_DD(paramFrom) : paramFrom;
	const to = paramTo instanceof Date ? YYYY_MM_DD(paramTo) : paramTo;
	dispatch(setFromAndTo({ from, to }));
};

const thunkSetType = type => (dispatch, getState) => {
	const key = sanitizeDateRangeTypeKey(type);
	const meta = getDateRangeTypeMeta(key);

	const root = selectThisSlice(getState());
	const currType = selectDateRangeType(root);
	const currFrom = selectDateRangeFrom(root);
	const currTo = selectDateRangeTo(root);

	if (key !== currType) {
		const from = meta.computeNewFrom(currFrom, currTo);
		const to = meta.computeNewTo(currFrom, currTo);

		dispatch(setType(key));
		dispatch(thunkSetFromAndTo({ from, to }));
	}
};

export { thunkSetFromAndTo, thunkSetType };

export default {
	thunkSetFromAndTo,
	thunkSetType
};
