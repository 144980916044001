import {
	selectIsDateRangeEnabled,
	selectThisSlice
} from '../selectors';
import { setEnabled } from './actions';
import { thunkSetType } from './thunks.core';
// import {
// 	thunkUpdateDateRangeUrl,
// 	thunkClearDateRangeUrlFilters
// } from './thunks.private.urlparams';

const thunkToggleDateRange = ({ onComplete = () => {} }) => (
	dispatch,
	getState
) => {
	const slice = selectThisSlice(getState());
	const enable = !selectIsDateRangeEnabled(slice);
	dispatch(setEnabled(enable));
	// if (enable) {
	// 	dispatch(thunkUpdateDateRangeUrl());
	// } else {
	// 	dispatch(thunkClearDateRangeUrlFilters());
	// }
	onComplete({ updatedUrl: true });
};

const thunkSetDateRangeTypeFromList = ({
	type,
	autoFilter,
	onComplete = () => {}
}) => dispatch => {
	// let updatedUrl = false;
	dispatch(thunkSetType(type));
	if (autoFilter) {
		dispatch(setEnabled(true));
	} else {
		dispatch(setEnabled(false));
	}
	setTimeout(() => {
		// if (autoFilter) {
		// 	dispatch(thunkUpdateDateRangeUrl());
		// 	updatedUrl = true;
		// }
		onComplete({ updatedUrl: false });
	}, 1);
};

const thunkFilterDateRange = ({ onComplete = () => {} }) => dispatch => {
	dispatch(setEnabled(true));
	// dispatch(thunkUpdateDateRangeUrl());
	// onComplete({ updatedUrl: true });
	onComplete({ updatedUrl: false });
};

export {
	thunkToggleDateRange,
	thunkSetDateRangeTypeFromList,
	thunkFilterDateRange
};

export default {
	thunkToggleDateRange,
	thunkSetDateRangeTypeFromList,
	thunkFilterDateRange
};
