/* eslint-disable camelcase */
import { getLocation, parseQueryString } from './location';

export const isShareUrl = objLocation => {
	const location = objLocation || getLocation() || {};
	const { pathname } = location;
	return pathname.toLowerCase() === '/scanline/share';
};

export const isMyProjectsMode = objLocation => {
	const location = objLocation || getLocation() || {};
	const { pathname } = location;
	return /\/my-projects/gi.test(pathname);
};

/**
 * @deprecated
 * @param {*} objLocation - optional
 * @returns jobId or undefined
 */
export const getJobId = objLocation => {
	const location = objLocation || getLocation() || {};
	const { job } = parseQueryString(location.search);
	return job;
};

export const getLineId = objLocation => {
	const location = objLocation || getLocation() || {};
	const { line } = parseQueryString(location.search);
	return line;
};

export const getLineName = objLocation => {
	const location = objLocation || getLocation() || {};
	const { linename, project } = parseQueryString(location.search);
	return !linename ? project : linename;
};

export const getProjectId = objLocation => {
	const location = objLocation || getLocation() || {};
	const { p_id } = parseQueryString(location.search);
	return p_id;
};

export const getProjectName = objLocation => {
	const location = objLocation || getLocation() || {};
	const { project } = parseQueryString(location.search);
	return project;
};

export const getLineOrProjectId = objLocation => {
	const projectId = getProjectId(objLocation);
	const lineId = getLineId(objLocation);
	return projectId || lineId;
};

export const getLineOrProjectKey = objLocation => {
	const projectId = getProjectId(objLocation);
	return projectId ? 'project' : 'line';
};

export default {
	isShareUrl,
	isMyProjectsMode,
	getJobId,
	getLineId,
	getLineName,
	getProjectId,
	getProjectName,
	getLineOrProjectId,
	getLineOrProjectKey
};
