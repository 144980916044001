import { createSelector } from 'reselect';
import { selectRootSliceLPR } from '../../Main/selectors';
import { ROOT_SLICE } from './constants';

export const selectThisSlice = state => {
	const slice = selectRootSliceLPR(state);
	if (slice[ROOT_SLICE]) {
		return slice[ROOT_SLICE];
	}

	// else assume state is already lines aggs.
	return state;
};

export const selectSortColumn = createSelector(
	[selectThisSlice],
	state => state.sortColumn
);

export const selectSortDirection = createSelector(
	[selectThisSlice],
	state => state.sortDirection
);
