import { createSelector } from 'reselect';

import { ROOT_SLICE } from './constants';
import PRIVATE from './selectors.util';
import { selectRootSliceLPR } from '../../Main/selectors';
import { selectFilteredLinesAggs } from '../LinkSurveysFiltersUI/selectors';
import { selectItems as selectLinesAggs } from '../LinesAggs/selectors';

const consoleLogDebugger = (txt, debug) => { 
  if (debug) {
    console.log(ROOT_SLICE);
    // debugger;
  }
}

export const selectThisSlice = (state, debug = false) => { 
  const slice = selectRootSliceLPR(state, debug);
  if (slice[ROOT_SLICE]) { 
    consoleLogDebugger('found', debug);
    return slice[ROOT_SLICE];
  }

  // else assume state is already LinkSurveysTableBehaviors.
  consoleLogDebugger('not found', debug);
  return state;
};

export const selectExpandedRowMapById = (state) => { 
  const slice = selectThisSlice(state);
  const { expandedRowMapById } = slice;
  return expandedRowMapById;
}

export const selectSelectedSurveysArrMapById = (state) => { 
  const slice = selectThisSlice(state);
  const { selectedSurveysArrMapById } = slice;
  return selectedSurveysArrMapById;
}


export const selectLinkSurveysLinesAggsWExpanded = createSelector(
  selectFilteredLinesAggs,
  selectExpandedRowMapById,
  (
    filteredLinesAggs,
    expandedRowMapById
  ) => {
    const expandedRowMapByIdWithOffsets = PRIVATE.computeExpandedRowMapByIdWithOffsets(expandedRowMapById);
    const linesAggsWithExpandedRows = PRIVATE.injectExpandedRows(filteredLinesAggs, expandedRowMapByIdWithOffsets);
    return linesAggsWithExpandedRows;
  });


export default {
  selectExpandedRowMapById,
  selectLinkSurveysLinesAggsWExpanded
};

export const selectSelectedSurveysMapMapById = createSelector(
  selectSelectedSurveysArrMapById,
  (lineAggMap) => {
    return PRIVATE.createSelectedSurveysMapMapById(lineAggMap);
  }
);

export const selectLinkSurveysSelectedSurveys = createSelector(
  selectLinesAggs,
  selectSelectedSurveysMapMapById,
  (
    linesAggs,
    lineAggSurveyMap
  ) => {
    const surveys = [];
    linesAggs
      .filter(la => lineAggSurveyMap[la.id] && typeof lineAggSurveyMap[la.id] === 'object' && Object.keys(lineAggSurveyMap[la.id]).length)
      .forEach(la => {
        la.jobs.forEach(job => {
          job.surveys.forEach(survey => {
            if (lineAggSurveyMap[la.id][survey.id]) {
              surveys.push(survey);
            }
          })
        })
      });
    return surveys;
  }
);

export const selectIsLinking = (state) => {
  const slice = selectThisSlice(state);
  const { isLinking } = slice;
  return isLinking;
};


export const selectLinkErrorMessage = (state) => {
  const slice = selectThisSlice(state);
  const { linkErrorMessage } = slice;
  return linkErrorMessage;
};

export const hasLinkError = (state) => {
  const errorMessage = selectLinkErrorMessage(state);
  return !!errorMessage;
}