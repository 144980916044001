import React, { useMemo } from 'react';

/** ****************************
 * HELPERS
 */

const EMPTY_OBJECT = {};
const DEFAULT_HEADER = 'Miles';
const PROP_KEY = 'miles';


/** ****************************
 * MAIN
 */

const TH = ({ header = DEFAULT_HEADER }) => <>{header}</>;

const TD = ({ item = EMPTY_OBJECT }) => {
  const value = useMemo(() => (item || {})[PROP_KEY] || '', [item]);

  return <td>{value}</td>;
};

const ColMiles = { TH, TD, CSV_PROP: PROP_KEY };

export default ColMiles;
