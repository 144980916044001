/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
import _ from 'lodash';
import {
  setIsEditorOpen,
  setSelectedItem,
  setData,
  setIsDataLoadingLoaded,
  setErrorMessage,
  clear
} from './actions';
import { ROOT_SLICE } from './constants';
import { initialState } from './initialState';
import {
  selectThisSlice,
  selectIsDataLoaded,
  selectIsDataLoading,
  selectErrorMessage
} from './selectors';

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
			[ROOT_SLICE]: _.cloneDeep(initialState)
		};
	},

  [setIsEditorOpen]: (state, { payload: { open } = {} } = {}) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        isEditorOpen: open
			}
		};
  },
  
  [setSelectedItem]: (state, { payload: { item } }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        selectedItem: item
			}
		};
  },
  
  [setData]: (state, { payload: { data } }) => {
    const rootSlice = selectThisSlice(state);
		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
        selectedItem: data
			}
		};
  },

	[setIsDataLoadingLoaded]: (
		state,
		{ payload: { isDataLoading: newIsLoading, isDataLoaded: newIsLoaded } }
	) => {
		const rootSlice = selectThisSlice(state);
		const isLoading = selectIsDataLoading(state);
		const isLoaded = selectIsDataLoaded(state);

		const CHANGED = {
			isLoading: isDefined(newIsLoading) && newIsLoading !== isLoading,
			isLoaded: isDefined(newIsLoaded) && newIsLoaded !== isLoaded
		};

		if (!CHANGED.isLoaded && !CHANGED.isLoading) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isDataLoading: CHANGED.isLoading ? newIsLoading : isLoading,
				iisDataLoaded: CHANGED.isLoaded ? newIsLoaded : isLoaded
			}
		};
	},

	[setErrorMessage]: (
		state,
		{ payload: { errorMessage: newErrorMessage } }
	) => {
		const rootSlice = selectThisSlice(state);
		const errorMessage = selectErrorMessage(state);

		if (newErrorMessage === errorMessage) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				errorMessage: newErrorMessage
			}
		};
  },

};

export default reducerParts;
