/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from './util/createPromiseRequest';
import { hasAccessToken } from '../../../utils/token';
import { injectAegionFilters, injectClientFilters } from './util/reportaggsfilters';

export const fetchReportsAggsFilters = async (customerName) => {
	const url = '/report-filters';

	if (hasAccessToken()) {
		const fetchData = createPromiseRequest({
      url,
      method: 'POST',
      data: {
        customer: customerName
      }      
		});

		return fetchData().then(res => injectClientFilters(injectAegionFilters(res)));
	}
	return Promise.reject(new Error('Missing Token'));
};

