import { getCustomer } from '../../../../util/user';
import { getFilters, KEY_COMPANY } from '../../../util/filters';

const startingFilters = getFilters();
const sessionCustomer = getCustomer();
const canChangeCustomer = sessionCustomer === 'Aegion';

export const initialState = {
	customer: canChangeCustomer
		? startingFilters[KEY_COMPANY] || sessionCustomer
		: sessionCustomer,
	restrictionDefinitions: {},
	mapRestrictionsByGroupName: {},
	listRestrictionDefinitions: [],
	mapRestrictionsListByAppKey: {},

	isLoadingRestrictionDefinitions: false,
	isLoadedRestrictionDefinitions: false,
	hasLoadingErrorRestrictionDefinitions: false
};

export default initialState;
